import { defineStore } from 'pinia';
import { isLobbyGamesItem } from 'web/src/modules/lobby/utils';
import { useEgsGamesStore } from 'web/src/modules/egs/submodules/games/store';
import { useEgsApi } from 'web/src/modules/egs/composables';
import { getJackpotGameIdsFromLobbyItems } from 'web/src/modules/lobby/store/utils';
const useLobbyJackpotsStore = defineStore('lobby-jackpots', ()=>{
    const gamesStore = useEgsGamesStore();
    const { getGames } = useEgsApi();
    async function updateLobbyJackpotGamesAmount(lobbyItems) {
        const gameIds = getJackpotGameIdsFromLobbyItems(lobbyItems);
        if (gameIds.length > 0) {
            const data = await getGames({
                gameIds
            }, true);
            const gamesRecords = data.games.reduce((accumulator, game)=>{
                // eslint-disable-next-line no-param-reassign
                accumulator[game.id] = game.jackpot?.amount;
                return accumulator;
            }, {});
            gamesStore.updateGames(data.games);
            for (const lobbyItem of lobbyItems)if (isLobbyGamesItem(lobbyItem)) lobbyItem.games = lobbyItem.games.map((game)=>{
                const { id, jackpot } = game;
                const newValue = gamesRecords[id];
                if (!jackpot || !newValue) return game;
                return {
                    ...game,
                    jackpot: {
                        amount: {
                            ...jackpot.amount,
                            ...newValue
                        }
                    }
                };
            });
        }
    }
    return {
        updateLobbyJackpotGamesAmount
    };
});
export default useLobbyJackpotsStore;
