import { useRoute, useRouter } from 'vue-router';
import { computed, ref, watch, toRef, nextTick } from 'vue';
import { EgsTopWinnersFilter, LobbyItemType } from '@leon-hub/api-sdk/src/sdk/sdk';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { isEnumOfType } from '@leon-hub/guards';
import RouteName from '@leon-hub/routing-config-names';
import { onComponentActivated } from '@leon-hub/vue-utils';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useLobbyStore } from 'web/src/modules/lobby/store';
import useEgsNavigation from 'web/src/modules/egs/submodules/navigation/composable/useEgsNavigation';
import { isLiveEgsRoute, navigateToCategoryPage, navigateToLobbyPage } from 'web/src/modules/egs/utils';
import { isEgsBannerItem, isEgsGame, isEgsGroup, isEgsTopWinner, isLobbyGamesItem, isLobbyTopWinnersItem, isPromotion, isSportlineTopMatchItem, isSwiperHasToGetNewItems } from 'web/src/modules/lobby/utils';
import { getLobbyItems, getLobbySectionProps, getSkeletonItems } from 'web/src/modules/lobby/components/LobbyBlock/composables/utils';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export default function useLobbyBlock(props, emit) {
    const { isLoggedIn } = useIsLoggedIn();
    const router = useRouter();
    const route = useRoute();
    const bus = useEventsBus();
    const lobbyStore = useLobbyStore();
    const { navigateToGamePage, isDemo } = useEgsNavigation(router);
    const filteredGroupId = computed(()=>props.groupBlock ? void 0 : props.groupId);
    const items = computed(()=>lobbyStore.getLobbyItems(props.lobbyType, filteredGroupId.value, props.categoryId).value);
    const lobbyItems = computed(()=>getLobbyItems(props, items.value));
    const externalGamesConfig = toRef(useSiteConfigStore(), 'externalGames');
    const container = ref();
    // eslint-disable-next-line max-len
    const isLoadingLobby = computed(()=>lobbyStore.getIsLoading(props.lobbyType, props.groupId, props.categoryId).value);
    const isLoadingPerf = ref(isLoadingLobby.value);
    const isLoadingTransition = ref(isLoadingLobby.value);
    const isContentLoading = computed(()=>isLoadingLobby.value || isLoadingPerf.value);
    const skeletonItems = computed(()=>getSkeletonItems(props, isContentLoading.value));
    // eslint-disable-next-line max-len
    const lobbySectionProps = computed(()=>getLobbySectionProps(props, isContentLoading.value, lobbyItems.value, router, externalGamesConfig.value?.lazyLoadPagesCounter ?? 0));
    function loadItems() {
        lobbyStore.loadLobbyItems({
            type: props.lobbyType,
            group: props.groupId,
            category: props.categoryId,
            excludeGameIds: props.excludeGameIds
        });
    }
    function fetchMoreGames(lobbyItem) {
        if (isLobbyGamesItem(lobbyItem)) lobbyStore.loadMoreLobbyItemGames({
            lobbyItem,
            groupId: props.groupId,
            isAllTab: props.isAllTab,
            excludeGameIds: props.excludeGameIds
        });
        if (isLobbyTopWinnersItem(lobbyItem)) lobbyStore.loadLobbyItemTopWinners({
            lobbyItem
        });
    }
    function onSwiperScroll(event, lobbyItem) {
        if (!lobbyItem) return;
        if (isSwiperHasToGetNewItems(event)) fetchMoreGames(lobbyItem);
    }
    function onSectionScroll(event, lobbyItem) {
        if (!lobbyItem) return;
        const clientHeight = window.innerHeight || document.documentElement.clientHeight;
        if (2 * clientHeight >= event.height + event.top) fetchMoreGames(lobbyItem);
    }
    function onTopWinnersFilterClick(filter, lobbyItem) {
        isEnumOfType(EgsTopWinnersFilter)(filter);
        if (!lobbyItem || lobbyItem.filter === filter) return;
        lobbyStore.loadLobbyItemTopWinners({
            lobbyItem,
            filter,
            isReplace: true
        });
    }
    function onSelectCategory(category) {
        navigateToCategoryPage(router, route, category, props.groupUrl);
    }
    function onChangeRoute(href) {
        router.push(href);
    }
    function onActionClick(lobbyItem) {
        if (!lobbyItem) return;
        const scrollTop = (route.name === CasinoRouteName.CASINO_LIVE_GROUPS || route.name === CasinoRouteName.CASINO_LOBBY) && (lobbyItem.typeId === LobbyItemType.GAMES_CATEGORY || lobbyItem.typeId === LobbyItemType.TOP_WINNERS);
        if (scrollTop) bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_TOP, {});
        if (lobbyItem.redirectPath) {
            onChangeRoute(lobbyItem.redirectPath);
            return;
        }
        switch(lobbyItem.typeId){
            case LobbyItemType.PROMOTIONS:
                onChangeRoute(lobbyItem.url);
                break;
            case LobbyItemType.TOP_WINNERS:
            case LobbyItemType.GAMES_CATEGORY:
                onSelectCategory(lobbyItem.category);
                break;
            case LobbyItemType.GROUPS:
                router.push({
                    name: isLiveEgsRoute(route) ? CasinoRouteName.CASINO_LIVE_GROUPS : CasinoRouteName.CASINO_GROUPS
                });
                break;
            case LobbyItemType.BANNERS:
                router.push({
                    name: RouteName.PROMOTIONS
                });
                break;
            case LobbyItemType.SPORTLINE_TOP_MATCHES:
                router.push({
                    name: RouteName.SPORTLINE_TOP_EVENTS
                });
                break;
            default:
                break;
        }
    }
    function onPlay(lobbyItem, game, selectedMethod) {
        navigateToGamePage(game, selectedMethod, props.shouldReplaceGameRoutes);
        emit('game-click', game, isDemo(game, selectedMethod), lobbyItem);
    }
    function onClickLobbyItem(data, lobbyItem) {
        if (!lobbyItem) return;
        switch(lobbyItem.typeId){
            case LobbyItemType.PROMOTIONS:
                isPromotion(data.item, lobbyItem.typeId);
                onChangeRoute(data.item.url);
                break;
            case LobbyItemType.TOP_WINNERS:
                isEgsTopWinner(data.item, lobbyItem.typeId);
                onPlay(lobbyItem, data.item.egsGame, data.selectedMethod);
                break;
            case LobbyItemType.GAMES_CATEGORY:
                isEgsGame(data.item, lobbyItem.typeId);
                onPlay(lobbyItem, data.item, data.selectedMethod);
                break;
            case LobbyItemType.BANNERS:
                isEgsBannerItem(data.item, lobbyItem.typeId);
                onChangeRoute(data.item.url);
                break;
            case LobbyItemType.SPORTLINE_TOP_MATCHES:
                isSportlineTopMatchItem(data.item, lobbyItem.typeId);
                router.push({
                    name: RouteName.SPORT_EVENT_DETAILS,
                    params: {
                        ...data.item.navigationParameters
                    }
                });
                break;
            case LobbyItemType.GROUPS:
                isEgsGroup(data.item, lobbyItem.typeId);
                useEventsBus().emit(BusEvent.LAYOUT_CONTENT_SCROLL_TOP, {});
                navigateToLobbyPage(router, route, data.item.url);
                break;
            default:
                break;
        }
    }
    function emitDeleteItems(ids) {
        emit('delete-items', ids);
    }
    onComponentActivated(()=>{
        if (!props.preventItemsFetch) loadItems();
    });
    watch(isLoadingLobby, async (newValue)=>{
        if (newValue) {
            isLoadingTransition.value = true;
            isLoadingPerf.value = true;
            // Wait 1 frame to render transition
            await nextTick();
            await nextAnimationFrame();
            isLoadingTransition.value = false;
            // Wait 2 frames to render skeleton
            await nextTick();
            await nextAnimationFrame();
            await nextAnimationFrame();
            isLoadingPerf.value = false;
        }
    }, {
        immediate: true
    });
    watch(isContentLoading, ()=>{
        if (container.value) for (const section of container.value)section.resetScrollPosition();
    });
    watch(isLoggedIn, ()=>{
        loadItems();
    });
    return {
        isLoadingTransition,
        isLoadingLobby,
        isContentLoading,
        skeletonItems,
        lobbySectionProps,
        lobbyItems,
        onSwiperScroll,
        onSectionScroll,
        onTopWinnersFilterClick,
        onClickLobbyItem,
        onActionClick,
        emitDeleteItems
    };
}
