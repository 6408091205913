// eslint-disable-next-line import/no-dynamic-require
const noImageSource = require("web/src/assets/images/li/default/noimage-portrait.png");
// eslint-disable-next-line import/no-dynamic-require
const noImageSquareSource = require("web/src/assets/images/li/default/noimage-square.png");
export default function getImageProperties(isImageLoadError, props) {
    const noImageSrc = props.isSquareImage ? noImageSquareSource : noImageSource;
    if (isImageLoadError || !props.item) return {
        src: noImageSrc
    };
    if (props.isSquareImage && props.item.images.squareImage) return props.item.images.squareImage;
    if (props.isJackpot && props.item.images.jackpot) return props.item.images.jackpot;
    return props.item.images.preview ?? {
        src: noImageSrc
    };
}
