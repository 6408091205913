import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useSwiperNavigation } from 'web/src/components/Swiper/Navigation/composables';
// eslint-disable-next-line max-len
import LobbySectionSwiperNavigationButtons from 'web/src/modules/lobby/components/LobbySectionSwiperNavigationButtons/LobbySectionSwiperNavigationButtons.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbySectionSwiperNavigation',
    props: {
        isModal: {
            type: Boolean
        }
    },
    setup (__props) {
        const { swiperState, slideToPreviousVisibleSlide, slideToNextVisibleSlide } = useSwiperNavigation();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(LobbySectionSwiperNavigationButtons, {
                "show-previous-button": _unref(swiperState)?.isPreviousButtonAllowed.value,
                "show-next-button": _unref(swiperState)?.isNextButtonAllowed.value,
                "is-modal": _ctx.isModal,
                onClickPrevious: _unref(slideToPreviousVisibleSlide),
                onClickNext: _unref(slideToNextVisibleSlide)
            }, null, 8, [
                "show-previous-button",
                "show-next-button",
                "is-modal",
                "onClickPrevious",
                "onClickNext"
            ])), [
                [
                    _directive_auto_id,
                    'LobbySectionSwiperNavigation'
                ]
            ]);
        };
    }
});
