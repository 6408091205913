import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { ButtonHeight, ButtonKind } from '@leon-hub/module-buttons';
import { Tag } from '@leon-hub/tags';
import LobbySectionHeader from 'web/src/modules/lobby/components/LobbySectionHeader/LobbySectionHeader.vue';
import VSwitcher from 'web/src/components/Switcher/VSwitcher/VSwitcher.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import LobbyAllTopWinnersTable from 'web/src/modules/lobby/components/LobbyAllTopWinnersTable/LobbyAllTopWinnersTable.vue';
import { useLobbyAllTopWinnersSection } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbyAllTopWinnersSection',
    props: {
        tab: {},
        headerTitle: {},
        headerIcon: {},
        topSports: {},
        topGames: {},
        isCustomHomepage: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { switcherOptions, items, isEgsTab, allWinnersHref, setActiveTab, onAllClick } = useLobbyAllTopWinnersSection(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['lobby-all-top-winners'])
            }, [
                _createVNode(LobbySectionHeader, {
                    title: _ctx.headerTitle,
                    "icon-url": _ctx.headerIcon,
                    "is-custom-homepage": _ctx.isCustomHomepage
                }, {
                    filters: _withCtx(()=>[
                            _createVNode(VSwitcher, {
                                options: _unref(switcherOptions),
                                class: _normalizeClass(_ctx.$style['lobby-all-top-winners__switcher']),
                                onChange: _unref(setActiveTab)
                            }, null, 8, [
                                "options",
                                "class",
                                "onChange"
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "title",
                    "icon-url",
                    "is-custom-homepage"
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['lobby-all-top-winners__table-wrapper'])
                }, [
                    _createVNode(LobbyAllTopWinnersTable, {
                        items: _unref(items),
                        "is-egs": _unref(isEgsTab)
                    }, null, 8, [
                        "items",
                        "is-egs"
                    ]),
                    _createVNode(VButton, {
                        label: _unref(isEgsTab) ? _ctx.$t('WEB2_ALL_WINNERS_TABLE_ALL_WINNERS_EGS') : _ctx.$t('WEB2_ALL_WINNERS_TABLE_ALL_WINNERS'),
                        "full-width": "",
                        height: _unref(ButtonHeight).SMALL,
                        kind: _unref(ButtonKind).BASE,
                        class: _normalizeClass(_ctx.$style['lobby-all-top-winners__btn']),
                        tag: _unref(Tag).A,
                        href: _unref(allWinnersHref),
                        onClick: _withModifiers(_unref(onAllClick), [
                            "prevent"
                        ])
                    }, null, 8, [
                        "label",
                        "height",
                        "kind",
                        "class",
                        "tag",
                        "href",
                        "onClick"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LobbyAllTopWinnersSection'
                ]
            ]);
        };
    }
});
