import { LobbyItemType } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { getLobbyLocation } from 'web/src/modules/egs/utils';
export function getLobbyActionHref(router, lobbyItem) {
    if (lobbyItem.redirectPath) return router.resolve(lobbyItem.redirectPath).href;
    switch(lobbyItem.typeId){
        case LobbyItemType.PROMOTIONS:
            return lobbyItem.url;
        case LobbyItemType.SPORTLINE_TOP_MATCHES:
            return router.resolve({
                name: RouteName.SPORTLINE_TOP_EVENTS
            }).href;
        case LobbyItemType.BANNERS:
            return router.resolve({
                name: RouteName.PROMOTIONS
            }).href;
        case LobbyItemType.GROUPS:
            return router.resolve({
                name: CasinoRouteName.CASINO_GROUPS
            }).href;
        case LobbyItemType.GAMES_CATEGORY:
        case LobbyItemType.TOP_WINNERS:
            return router.resolve(getLobbyLocation(router.currentRoute.value, router.currentRoute.value.params.groupUrl, lobbyItem.category.url, lobbyItem.category.type)).href;
        default:
            return;
    }
}
