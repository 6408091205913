import { getLobbyActionHref } from 'web/src/modules/lobby/components/LobbyBlock/composables/utils/getLobbyActionHref';
export default function getLobbySectionProps(props, isLoadingLobby, lobbyItems, router, lazyLoadPagesCounter) {
    if (isLoadingLobby) return [];
    return lobbyItems.map((lobbyItem)=>({
            lobbyItem,
            isModal: props.isModal,
            noBackground: props.noBackground,
            jackpotRequestInterval: 15000,
            actionHref: getLobbyActionHref(router, lobbyItem),
            isHeaderWithHorizontalMargin: props.isHeaderWithHorizontalMargin,
            lazyLoadPagesCounter,
            isFiltersAllowed: props.isFiltersAllowed,
            isCustomHomepage: props.isCustomHomepage
        }));
}
