import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { SystemIconName, IconSize } from '@leon-hub/icons';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useEgsNavigation } from 'web/src/modules/egs/submodules/navigation/composable';
export default function useLobbyEgsTopWinnerItem(props) {
    const { $translate } = useI18n();
    const formatMoney = useFormatMoney();
    const router = useRouter();
    const { getEgsGameHref } = useEgsNavigation(router);
    const iconProperties = {
        name: SystemIconName.PERSON,
        size: IconSize.SIZE_16
    };
    const imageProperties = computed(()=>props.item?.egsGame.images.preview ?? {
            // eslint-disable-next-line import/no-dynamic-require
            src: require("web/src/assets/images/li/default/noimage-portrait.png")
        });
    const playButtonProperties = computed(()=>({
            rounded: true,
            kind: ButtonKind.PRIMARY,
            label: $translate('BUTTON_PLAY').value,
            height: ButtonHeight.TINY
        }));
    const ntBadgeProperties = computed(()=>({
            kind: BadgeKind.SQUARE_ERROR,
            label: props.item?.egsGame.ntBadge || ''
        }));
    const moneyAmount = computed(()=>props.item ? formatMoney(props.item.winAmount.value, {
            currency: props.item.winAmount.currency,
            reduceAmount: 1000000
        }) : '');
    const href = computed(()=>props.item ? getEgsGameHref(props.item.egsGame) : void 0);
    return {
        imageProperties,
        iconProperties,
        playButtonProperties,
        ntBadgeProperties,
        moneyAmount,
        href
    };
}
