import { LobbyItemType } from '@leon-hub/api-sdk';
export function isEgsGame(item, typeId) {
    return !!item && typeId === LobbyItemType.GAMES_CATEGORY;
}
export function isPromotion(item, typeId) {
    return !!item && typeId === LobbyItemType.PROMOTIONS;
}
export function isEgsGroup(item, typeId) {
    return !!item && typeId === LobbyItemType.GROUPS;
}
export function isEgsTopWinner(item, typeId) {
    return !!item && typeId === LobbyItemType.TOP_WINNERS;
}
export function isEgsBannerItem(item, typeId) {
    return !!item && typeId === LobbyItemType.BANNERS;
}
export function isSportlineTopMatchItem(item, typeId) {
    return !!item && typeId === LobbyItemType.SPORTLINE_TOP_MATCHES;
}
