import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import LobbySwiper from 'web/src/modules/lobby/components/LobbySwiper/LobbySwiper.vue';
import LobbySectionHeader from 'web/src/modules/lobby/components/LobbySectionHeader/LobbySectionHeader.vue';
import LobbySectionSwiperNavigation from 'web/src/modules/lobby/components/LobbySectionSwiperNavigation/LobbySectionSwiperNavigation.vue';
import { useLobbySwiperSection } from 'web/src/modules/lobby/components/LobbySwiperSection/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbySwiperSection',
    props: {
        isSwiperAuto: {
            type: Boolean
        },
        rowsNumber: {},
        isSwiperBlock: {
            type: Boolean
        },
        background: {},
        widgetId: {},
        gridType: {},
        lobbyItemType: {},
        lobbyItems: {},
        actionLabel: {},
        actionHref: {},
        headerName: {},
        isModal: {
            type: Boolean
        },
        hasNext: {
            type: Boolean
        },
        total: {},
        isFirstPage: {
            type: Boolean
        },
        jackpotAnimationDuration: {},
        showSkeleton: {
            type: Boolean
        },
        topWinnersFilter: {},
        isItemNameVisible: {
            type: Boolean
        },
        isEditable: {
            type: Boolean
        },
        headerIcon: {},
        titleType: {},
        isHeaderWithHorizontalMargin: {
            type: Boolean
        },
        isMoreLoading: {
            type: Boolean
        },
        isLoadMoreButtonVisible: {
            type: Boolean
        },
        isFiltersAllowed: {
            type: Boolean
        },
        noBackground: {
            type: Boolean
        },
        isCustomHomepage: {
            type: Boolean
        }
    },
    emits: [
        "click-lobby-item",
        "delete-items",
        "action-click",
        "top-winners-filter-click",
        "section-scroll",
        "swiper-scroll"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { swiper, lobbySwiperProps, headerProps, isHeaderVisible, emitActionClick, emitClickLobbyItem, emitSwiperScroll, resetScrollPosition } = useLobbySwiperSection(props, emits);
        __expose({
            resetScrollPosition
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(LobbySwiper, _mergeProps({
                ref_key: "swiper",
                ref: swiper
            }, _unref(lobbySwiperProps), {
                class: {
                    [_ctx.$style['lobby-swiper-section']]: true,
                    [_ctx.$style['lobby-swiper-section--no-background']]: _ctx.noBackground,
                    [_ctx.$style[`lobby-swiper-section--${_ctx.lobbyItemType.toLowerCase()}`]]: true,
                    [_ctx.$style[`lobby-swiper-section--background__${_ctx.background}`]]: !_ctx.noBackground && !!_ctx.background
                },
                onSwiperScroll: _unref(emitSwiperScroll),
                onClickLobbyItem: _unref(emitClickLobbyItem)
            }), _createSlots({
                _: 2
            }, [
                _unref(isHeaderVisible) ? {
                    name: "header",
                    fn: _withCtx(()=>[
                            _createVNode(LobbySectionHeader, _mergeProps(_unref(headerProps), {
                                "no-background": _ctx.noBackground && !_ctx.isModal,
                                class: _ctx.$style['lobby-swiper-section__header'],
                                onActionClick: _unref(emitActionClick)
                            }), _createSlots({
                                _: 2
                            }, [
                                _ctx.$slots.headerIcon ? {
                                    name: "icon",
                                    fn: _withCtx(()=>[
                                            _renderSlot(_ctx.$slots, "headerIcon")
                                        ]),
                                    key: "0"
                                } : void 0,
                                {
                                    name: "navigation",
                                    fn: _withCtx(()=>[
                                            _createVNode(LobbySectionSwiperNavigation, {
                                                "is-modal": _ctx.isModal
                                            }, null, 8, [
                                                "is-modal"
                                            ])
                                        ]),
                                    key: "1"
                                }
                            ]), 1040, [
                                "no-background",
                                "class",
                                "onActionClick"
                            ])
                        ]),
                    key: "0"
                } : void 0
            ]), 1040, [
                "class",
                "onSwiperScroll",
                "onClickLobbyItem"
            ])), [
                [
                    _directive_auto_id,
                    'LobbySwiperSection'
                ]
            ]);
        };
    }
});
