import { LobbyItemType } from '@leon-hub/api-sdk/src/sdk/sdk';
export default function getTestType(props) {
    switch(props.lobbyItemType){
        case LobbyItemType.PROMOTIONS:
            return 'promotion';
        case LobbyItemType.GROUPS:
            return 'group';
        case LobbyItemType.TOP_WINNERS:
            return 'top-winner';
        case LobbyItemType.GAMES_CATEGORY:
            return 'game';
        default:
            return props.lobbyItemType;
    }
}
