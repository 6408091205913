import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src",
    "alt"
];
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { SportIcon } from '@components/sport-icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbyAllTopWinnersTable',
    props: {
        items: {},
        isEgs: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['lobby-all-winners-table'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['lobby-all-winners-table__row'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass([
                            _ctx.$style['lobby-all-winners-table__cell'],
                            _ctx.$style['lobby-all-winners-table__cell--header'],
                            _ctx.$style['lobby-all-winners-table__cell--name']
                        ])
                    }, _toDisplayString(_ctx.isEgs ? _ctx.$t('WEB2_ALL_WINNERS_TABLE_GAME') : _ctx.$t('WEB2_ALL_WINNERS_TABLE_EVENT')), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass([
                            _ctx.$style['lobby-all-winners-table__cell'],
                            _ctx.$style['lobby-all-winners-table__cell--header'],
                            _ctx.$style['lobby-all-winners-table__cell--user']
                        ])
                    }, _toDisplayString(_ctx.$t('WEB2_ALL_WINNERS_TABLE_USER')), 3),
                    _ctx.isEgs ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass([
                            _ctx.$style['lobby-all-winners-table__cell'],
                            _ctx.$style['lobby-all-winners-table__cell--header'],
                            _ctx.$style['lobby-all-winners-table__cell--coeff']
                        ])
                    }, _toDisplayString(_ctx.$t('WEB2_ALL_WINNERS_TABLE_COEF')), 3)),
                    _createElementVNode("div", {
                        class: _normalizeClass([
                            _ctx.$style['lobby-all-winners-table__cell'],
                            _ctx.$style['lobby-all-winners-table__cell--header'],
                            _ctx.$style['lobby-all-winners-table__cell--win']
                        ])
                    }, _toDisplayString(_ctx.$t('WEB2_ALL_WINNERS_TABLE_WIN')), 3)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['lobby-all-winners-table__tbody'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index)=>(_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: _normalizeClass([
                                _ctx.$style['lobby-all-winners-table__row'],
                                _ctx.$style['lobby-all-winners-table__row--body']
                            ])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass([
                                    _ctx.$style['lobby-all-winners-table__cell'],
                                    _ctx.$style['lobby-all-winners-table__cell--name'],
                                    _ctx.$style['lobby-all-winners-table__cell--name-item']
                                ])
                            }, [
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['lobby-all-winners-table__icon'])
                                }, [
                                    item.iconSrc ? (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        src: item.iconSrc,
                                        alt: item.name,
                                        class: _normalizeClass(_ctx.$style['lobby-all-winners-table__icon-img'])
                                    }, null, 10, _hoisted_1)) : item.sportName ? (_openBlock(), _createBlock(_unref(SportIcon), {
                                        key: 1,
                                        "sport-name": item.sportName,
                                        size: _unref(IconSize).SIZE_16
                                    }, null, 8, [
                                        "sport-name",
                                        "size"
                                    ])) : item.iconName ? (_openBlock(), _createBlock(_unref(VIcon), {
                                        key: 2,
                                        size: _unref(IconSize).SIZE_16,
                                        name: item.iconName
                                    }, null, 8, [
                                        "size",
                                        "name"
                                    ])) : (_openBlock(), _createBlock(_unref(VIcon), {
                                        key: 3,
                                        size: _unref(IconSize).SIZE_16,
                                        name: _unref(IconName).CUP
                                    }, null, 8, [
                                        "size",
                                        "name"
                                    ]))
                                ], 2),
                                _createTextVNode(" " + _toDisplayString(item.name), 1)
                            ], 2),
                            _createElementVNode("div", {
                                class: _normalizeClass([
                                    _ctx.$style['lobby-all-winners-table__cell'],
                                    _ctx.$style['lobby-all-winners-table__cell--user'],
                                    _ctx.$style['lobby-all-winners-table__cell--user-item']
                                ])
                            }, _toDisplayString(item.userName), 3),
                            _ctx.isEgs ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass([
                                    _ctx.$style['lobby-all-winners-table__cell'],
                                    _ctx.$style['lobby-all-winners-table__cell--coeff'],
                                    _ctx.$style['lobby-all-winners-table__cell--coeff-item']
                                ])
                            }, _toDisplayString(item.coefficient), 3)),
                            _createElementVNode("div", {
                                class: _normalizeClass([
                                    _ctx.$style['lobby-all-winners-table__cell'],
                                    _ctx.$style['lobby-all-winners-table__cell--win'],
                                    _ctx.$style['lobby-all-winners-table__cell--win-item']
                                ])
                            }, _toDisplayString(item.win), 3)
                        ], 2))), 128))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LobbyAllTopWinnersTable'
                ]
            ]);
        };
    }
});
