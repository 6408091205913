import { computed, ref } from 'vue';
import { LobbyTitleType } from '@leon-hub/api-sdk';
import { isEgsGame } from 'web/src/modules/lobby/utils';
export function useLobbySection(props, emits) {
    const isEdit = ref(false);
    const gameIdsToRemove = ref([]);
    const isEditBtnEnabled = computed(()=>props.isEditable && props.lobbyItems.length > 0);
    const isSmallHeader = computed(()=>props.titleType === LobbyTitleType.SMALL);
    const isHeaderVisible = computed(()=>props.titleType !== LobbyTitleType.HIDDEN);
    const headerProps = computed(()=>({
            actionLabel: props.actionLabel,
            actionHref: props.actionHref,
            title: props.headerName,
            isSmall: isSmallHeader.value,
            isModal: props.isModal,
            iconUrl: props.headerIcon,
            isHeaderWithHorizontalMargin: props.isHeaderWithHorizontalMargin,
            isLoading: props.showSkeleton,
            noBackground: props.noBackground,
            isCustomHomepage: props.isCustomHomepage
        }));
    const lobbyGridBaseProps = computed(()=>({
            items: props.lobbyItems,
            lobbyItemType: props.lobbyItemType,
            widgetId: props.widgetId,
            isModal: props.isModal,
            noBackground: props.noBackground,
            lobbyGridType: props.gridType,
            jackpotAnimationDuration: props.jackpotAnimationDuration,
            hasNext: props.hasNext,
            isItemNameVisible: props.isItemNameVisible,
            isEditMode: isEdit.value,
            gameIdsToRemove: gameIdsToRemove.value,
            isLoadMoreButtonVisible: props.isLoadMoreButtonVisible
        }));
    function onEditClick() {
        if (isEdit.value) {
            if (gameIdsToRemove.value.length > 0) emitDeleteItems(gameIdsToRemove.value);
            gameIdsToRemove.value = [];
        }
        isEdit.value = !isEdit.value;
    }
    function onClickLobbyItem(data) {
        if (!isEdit.value) {
            emitClickLobbyItem(data);
            return;
        }
        isEgsGame(data.item, props.lobbyItemType);
        const { id } = data.item;
        if (gameIdsToRemove.value.includes(id)) gameIdsToRemove.value = gameIdsToRemove.value.filter((gameId)=>gameId !== id);
        else gameIdsToRemove.value.push(id);
    }
    function emitClickLobbyItem(data) {
        emits('click-lobby-item', data);
    }
    function emitDeleteItems(data) {
        emits('delete-items', data);
    }
    function emitActionClick() {
        emits('action-click');
    }
    return {
        lobbyGridBaseProps,
        isEdit,
        headerProps,
        isSmallHeader,
        isEditBtnEnabled,
        isHeaderVisible,
        emitActionClick,
        emitClickLobbyItem,
        onEditClick,
        onClickLobbyItem
    };
}
