import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import LobbyItem from 'web/src/modules/lobby/components/LobbyItem/LobbyItem.vue';
import useLobbyGrid from 'web/src/modules/lobby/components/composables/useLobbyGrid';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbyGrid',
    props: {
        items: {},
        lobbyItemType: {},
        widgetId: {},
        isModal: {
            type: Boolean
        },
        lobbyGridType: {},
        jackpotAnimationDuration: {},
        hasNext: {
            type: Boolean
        },
        isItemNameVisible: {
            type: Boolean
        },
        isEditMode: {
            type: Boolean
        },
        gameIdsToRemove: {},
        noBackground: {
            type: Boolean
        },
        isLoadMoreButtonVisible: {
            type: Boolean
        }
    },
    emits: [
        "click-lobby-item",
        "swiper-scroll"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { getItemKey, gridType, getLobbyItemProps, emitClickLobbyItem } = useLobbyGrid(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['lobby-grid']]: true,
                    [_ctx.$style[`lobby-grid--type-${_unref(gridType)}`]]: true
                })
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index)=>(_openBlock(), _createBlock(LobbyItem, _mergeProps({
                        key: _unref(getItemKey)(item, index),
                        ref_for: true
                    }, _unref(getLobbyItemProps)(item, index), {
                        class: _ctx.$style['lobby-grid__item'],
                        onClickLobbyItem: _unref(emitClickLobbyItem)
                    }), null, 16, [
                        "class",
                        "onClickLobbyItem"
                    ]))), 128)),
                _ctx.hasNext && !_ctx.isLoadMoreButtonVisible ? (_openBlock(), _createBlock(LobbyItem, _mergeProps({
                    key: 0
                }, _unref(getLobbyItemProps)(void 0, 0), {
                    class: _ctx.$style['lobby-grid__item']
                }), null, 16, [
                    "class"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LobbyGrid'
                ]
            ]);
        };
    }
});
