import { defineComponent as _defineComponent } from 'vue';
import { withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import LobbyEgsGamesItemImage from 'web/src/modules/lobby/components/LobbyEgsGamesItemImage/LobbyEgsGamesItemImage.vue';
import { useEgsNavigation } from 'web/src/modules/egs/submodules/navigation/composable';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbyEgsGamesItem',
    props: {
        item: {},
        isDescriptionHidden: {
            type: Boolean
        },
        isPanelVisible: {
            type: Boolean
        },
        isSquareImage: {
            type: Boolean
        },
        isJackpot: {
            type: Boolean
        },
        isEditMode: {
            type: Boolean
        },
        isRemoved: {
            type: Boolean
        },
        hasImageForceLoad: {
            type: Boolean
        },
        jackpotAnimationDuration: {},
        isNameVisible: {
            type: Boolean
        }
    },
    emits: [
        "click",
        "click-play",
        "click-demo"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const router = useRouter();
        const { getEgsGameHref } = useEgsNavigation(router);
        const href = computed(()=>props.item ? getEgsGameHref(props.item) : void 0);
        function onClick(event) {
            if (props.item) emit('click', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(href.value ? 'a' : 'div'), {
                class: _normalizeClass(_ctx.$style['lobby-egs-games-item']),
                href: href.value,
                onClick: _withModifiers(onClick, [
                    "prevent"
                ])
            }, {
                default: _withCtx(()=>[
                        _createVNode(LobbyEgsGamesItemImage, {
                            item: _ctx.item,
                            "has-image-force-load": _ctx.hasImageForceLoad,
                            "is-removed": _ctx.isRemoved,
                            "is-edit-mode": _ctx.isEditMode,
                            "jackpot-animation-duration": _ctx.jackpotAnimationDuration,
                            "is-jackpot": _ctx.isJackpot,
                            "is-square-image": _ctx.isSquareImage,
                            "is-panel-visible": _ctx.isPanelVisible,
                            "is-description-hidden": _ctx.isDescriptionHidden,
                            onClickPlay: _cache[0] || (_cache[0] = _withModifiers(($event)=>emit('click-play', $event), [
                                "stop",
                                "prevent"
                            ])),
                            onClickDemo: _cache[1] || (_cache[1] = _withModifiers(($event)=>emit('click-demo', $event), [
                                "stop",
                                "prevent"
                            ])),
                            onClick: _cache[2] || (_cache[2] = _withModifiers(($event)=>emit('click', $event), [
                                "stop",
                                "prevent"
                            ]))
                        }, null, 8, [
                            "item",
                            "has-image-force-load",
                            "is-removed",
                            "is-edit-mode",
                            "jackpot-animation-duration",
                            "is-jackpot",
                            "is-square-image",
                            "is-panel-visible",
                            "is-description-hidden"
                        ]),
                        _ctx.isNameVisible ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['lobby-egs-games-item__name-wrapper'])
                        }, [
                            _ctx.item ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['lobby-egs-games-item__name'])
                            }, _toDisplayString(_ctx.item.name), 3)) : (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['lobby-egs-games-item__name--skeleton'])
                            }, null, 2))
                        ], 2)) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "class",
                "href"
            ])), [
                [
                    _directive_auto_id,
                    'LobbyEgsGamesItem'
                ]
            ]);
        };
    }
});
