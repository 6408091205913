import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { useLobbyPromotionTitlePanel } from 'web/src/modules/lobby/components/LobbyPromotionTitlePanel/composable';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbyPromotionTitlePanel',
    props: {
        title: {},
        description: {
            default: ''
        }
    },
    setup (__props) {
        const props = __props;
        const { formattedText } = useLobbyPromotionTitlePanel(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['lobby-promotion-title-panel'])
            }, [
                _createElementVNode("p", {
                    class: _normalizeClass(_ctx.$style['lobby-promotion-title-panel__title'])
                }, _toDisplayString(_ctx.title), 3),
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['lobby-promotion-title-panel__description'])
                }, _toDisplayString(_unref(formattedText)), 3)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LobbyPromotionTitlePanel'
                ]
            ]);
        };
    }
});
