import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ButtonHeight, ButtonKind } from '@leon-hub/module-buttons';
import { IconName } from '@leon-hub/icons';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbySectionSwiperNavigationButtons',
    props: {
        showPreviousButton: {
            type: Boolean
        },
        showNextButton: {
            type: Boolean
        },
        isModal: {
            type: Boolean
        }
    },
    emits: [
        "click-previous",
        "click-next"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['lobby-section-swiper-navigation'])
            }, [
                (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createVNode(VButton, {
                        kind: _unref(ButtonKind).BASE,
                        height: _unref(ButtonHeight).MEDIUM,
                        "icon-name": _unref(IconName).EXPAND_LEFT,
                        disabled: !_ctx.showPreviousButton,
                        class: _normalizeClass(_ctx.$style['lobby-section-swiper-navigation__button']),
                        onClick: _cache[2] || (_cache[2] = ($event)=>emits('click-previous'))
                    }, null, 8, [
                        "kind",
                        "height",
                        "icon-name",
                        "disabled",
                        "class"
                    ]),
                    _createVNode(VButton, {
                        kind: _unref(ButtonKind).BASE,
                        height: _unref(ButtonHeight).MEDIUM,
                        "icon-name": _unref(IconName).EXPAND_RIGHT,
                        disabled: !_ctx.showNextButton,
                        class: _normalizeClass(_ctx.$style['lobby-section-swiper-navigation__button']),
                        onClick: _cache[3] || (_cache[3] = ($event)=>emits('click-next'))
                    }, null, 8, [
                        "kind",
                        "height",
                        "icon-name",
                        "disabled",
                        "class"
                    ])
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'LobbySectionSwiperNavigationButtons'
                ],
                [
                    _vShow,
                    _ctx.showPreviousButton || _ctx.showNextButton
                ]
            ]);
        };
    }
});
