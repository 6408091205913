import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useLobbyEgsGamesItemButtonsHover } from 'web/src/modules/lobby/components/LobbyEgsGamesItemButtonsHover/composables';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbyEgsGamesItemButtonsHover',
    props: {
        isDemoAvailable: {
            type: Boolean
        }
    },
    emits: [
        "click-play",
        "click-demo",
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const { playButtonProperties, demoButtonProperties } = useLobbyEgsGamesItemButtonsHover();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['lobby-egs-games-item-buttons-hover'])
            }, [
                (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createVNode(VButton, _mergeProps(_unref(playButtonProperties), {
                        class: _ctx.$style['lobby-egs-games-item-buttons-hover__btn'],
                        onClick: _cache[1] || (_cache[1] = ($event)=>emits('click-play', $event))
                    }), null, 16, [
                        "class"
                    ]),
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['lobby-egs-games-item-buttons-hover__btn']]: true,
                            [_ctx.$style['lobby-egs-games-item-buttons-hover__btn--demo']]: true
                        })
                    }, [
                        _ctx.isDemoAvailable ? (_openBlock(), _createBlock(VButton, _mergeProps({
                            key: 0
                        }, _unref(demoButtonProperties), {
                            onClick: _cache[2] || (_cache[2] = ($event)=>emits('click-demo', $event))
                        }), null, 16)) : _createCommentVNode("", true)
                    ], 2)
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'LobbyEgsGamesItemButtonsHover'
                ]
            ]);
        };
    }
});
