import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ObserveVisibility } from 'vue3-observe-visibility';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import LobbyItem from 'web/src/modules/lobby/components/LobbyItem/LobbyItem.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import { useLobbySwiper } from 'web/src/modules/lobby/components/LobbySwiper/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbySwiper',
    props: {
        isSwiperAuto: {
            type: Boolean
        },
        rowsNumber: {},
        isSwiperBlock: {
            type: Boolean
        },
        items: {},
        lobbyItemType: {},
        widgetId: {},
        isModal: {
            type: Boolean
        },
        noBackground: {
            type: Boolean
        },
        lobbyGridType: {},
        jackpotAnimationDuration: {},
        hasNext: {
            type: Boolean
        },
        isItemNameVisible: {
            type: Boolean
        },
        isEditMode: {
            type: Boolean
        },
        gameIdsToRemove: {},
        isLoadMoreButtonVisible: {
            type: Boolean
        }
    },
    emits: [
        "click-lobby-item",
        "swiper-scroll"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { swiper, gridType, slideGridType, swiperProps, emitSwiperScroll, onVisibilityChanged, swiperItems, isOverflowHidden, getItemKey, getLobbyItemProps, emitClickLobbyItem, gridSize, resetScrollPosition } = useLobbySwiper(props, emits);
        __expose({
            resetScrollPosition
        });
        const vObserveVisibility = ObserveVisibility;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['lobby-swiper']]: true,
                    [_ctx.$style[`lobby-swiper--type-${_unref(gridType)}`]]: !_ctx.isSwiperBlock,
                    [_ctx.$style[`lobby-swiper--grid-type-${_unref(slideGridType)}`]]: true,
                    [_ctx.$style['lobby-swiper--block']]: _ctx.isSwiperBlock,
                    [_ctx.$style['lobby-swiper--overflow-hidden']]: _unref(isOverflowHidden)
                })
            }, [
                _createVNode(_unref(VSwiper), _mergeProps({
                    ref_key: "swiper",
                    ref: swiper
                }, _unref(swiperProps), {
                    "wrapper-class": {
                        [_ctx.$style['lobby-swiper__swiper-wrapper']]: true,
                        [_ctx.$style['lobby-swiper__swiper-wrapper--modal']]: _ctx.isModal,
                        [_ctx.$style['lobby-swiper__swiper-wrapper--no-background']]: _ctx.noBackground
                    },
                    onScroll: _unref(emitSwiperScroll)
                }), {
                    "pagination-header": _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "header")
                        ]),
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(swiperItems), (slideItem, slideIndex)=>(_openBlock(), _createBlock(VSwiperSlide, {
                                    key: slideIndex,
                                    class: _normalizeClass({
                                        [_ctx.$style['lobby-swiper__slide']]: true,
                                        [_ctx.$style['lobby-swiper__slide--modal']]: _ctx.isModal,
                                        [_ctx.$style['lobby-swiper__slide--no-background']]: _ctx.noBackground
                                    })
                                }, {
                                    default: _withCtx(()=>[
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['lobby-swiper__grid'])
                                            }, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slideItem, (item, index)=>(_openBlock(), _createBlock(LobbyItem, _mergeProps({
                                                        key: _unref(getItemKey)(item, index),
                                                        ref_for: true
                                                    }, _unref(getLobbyItemProps)(item, index), {
                                                        "has-image-force-load": _ctx.isSwiperBlock,
                                                        class: _ctx.$style['lobby-swiper__item'],
                                                        onClickLobbyItem: _unref(emitClickLobbyItem)
                                                    }), null, 16, [
                                                        "has-image-force-load",
                                                        "class",
                                                        "onClickLobbyItem"
                                                    ]))), 128))
                                            ], 2)
                                        ]),
                                    _: 2
                                }, 1032, [
                                    "class"
                                ]))), 128)),
                            _ctx.hasNext ? (_openBlock(), _createBlock(VSwiperSlide, {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['lobby-swiper__slide'])
                            }, {
                                default: _withCtx(()=>[
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['lobby-swiper__grid'])
                                        }, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(gridSize), (index)=>(_openBlock(), _createBlock(LobbyItem, _mergeProps({
                                                    key: index,
                                                    ref_for: true
                                                }, _unref(getLobbyItemProps)(void 0, 0), {
                                                    class: _ctx.$style['lobby-swiper__item']
                                                }), null, 16, [
                                                    "class"
                                                ]))), 128))
                                        ], 2)
                                    ]),
                                _: 1
                            }, 8, [
                                "class"
                            ])) : _createCommentVNode("", true)
                        ]),
                    _: 3
                }, 16, [
                    "wrapper-class",
                    "onScroll"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'LobbySwiper'
                ],
                [
                    _unref(vObserveVisibility),
                    {
                        callback: _unref(onVisibilityChanged),
                        once: true,
                        rootMargin: '100px 100px 100px 100px'
                    }
                ]
            ]);
        };
    }
});
