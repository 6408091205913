import { computed } from 'vue';
import { useTheme } from 'web/src/modules/theme/composables';
import { rgbaColor } from 'web/src/modules/lobby/components/LobbyPromotionItem/composables/utils';
export default function useLobbyPromotionItem(props) {
    const { isLight } = useTheme();
    const backgroundString = computed(()=>{
        if (!props.item) return;
        const { bgColor } = props.item;
        return `linear-gradient(0deg, ${rgbaColor(bgColor)}, ${rgbaColor(bgColor)}), rgba${isLight.value ? '(255, 255, 255, 0.9)' : '(0, 0, 0, 0.9)'}`;
    });
    return {
        backgroundString
    };
}
