import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots, withModifiers as _withModifiers, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import EgsGameInfoOverlay from 'web/src/modules/egs/components/EgsGameInfoOverlay/EgsGameInfoOverlay.vue';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import LobbyEgsGamesBadge from 'web/src/modules/lobby/components/LobbyEgsGamesBadge/LobbyEgsGamesBadge.vue';
import EgsJackpotAnimatedAmount from 'web/src/modules/egs/submodules/jackpots/components/EgsJackpotAnimatedAmount/EgsJackpotAnimatedAmount.vue';
import LobbyEgsGamesItemButtonsHover from 'web/src/modules/lobby/components/LobbyEgsGamesItemButtonsHover/LobbyEgsGamesItemButtonsHover.vue';
import EgsFavorite from 'web/src/modules/egs/components/EgsFavorite/EgsFavorite.vue';
import VSkeletonImage from 'web/src/components/Skeleton/VSkeletonImage/VSkeletonImage.vue';
import EgsExclusiveBadge from 'web/src/modules/egs/components/EgsExclusiveBadge/EgsExclusiveBadge.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import EgsGameItemBottomBadge from 'web/src/modules/egs/components/EgsGameItemBottomBadge/EgsGameItemBottomBadge.vue';
import { useLobbyEgsGamesItemImage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbyEgsGamesItemImage',
    props: {
        item: {},
        isPanelVisible: {
            type: Boolean
        },
        hasImageForceLoad: {
            type: Boolean
        },
        isDescriptionHidden: {
            type: Boolean
        },
        jackpotAnimationDuration: {},
        isSquareImage: {
            type: Boolean
        },
        isJackpot: {
            type: Boolean
        },
        isEditMode: {
            type: Boolean
        },
        isRemoved: {
            type: Boolean
        }
    },
    emits: [
        "click",
        "click-play",
        "click-demo"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { isDemoAvailable, showPanel, imageProperties, betRange, onLazyImageMounted, onImageError, onImageLoaded } = useLobbyEgsGamesItemImage(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['lobby-egs-games-item-image']]: true,
                    [_ctx.$style['lobby-egs-games-item-image--square']]: _ctx.isSquareImage,
                    [_ctx.$style['lobby-egs-games-item-image--jackpot']]: _ctx.isJackpot
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['lobby-egs-games-item-image__image-content'])
                }, [
                    _ctx.item ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _createVNode(VImage, _mergeProps({
                            ref: "image"
                        }, _unref(imageProperties), {
                            "is-lazy": "",
                            "show-loader": "",
                            "object-fit": _unref(ObjectFitOption).COVER,
                            class: _ctx.$style['lobby-egs-games-item-image__image'],
                            onLazyImageMounted: _unref(onLazyImageMounted),
                            onError: _unref(onImageError),
                            onImageLoaded: _unref(onImageLoaded)
                        }), null, 16, [
                            "object-fit",
                            "class",
                            "onLazyImageMounted",
                            "onError",
                            "onImageLoaded"
                        ]),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['lobby-egs-games-item-image__badges'])
                        }, [
                            _ctx.item.exclusive ? (_openBlock(), _createBlock(EgsExclusiveBadge, {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['lobby-egs-games-item-image__exclusive'])
                            }, null, 8, [
                                "class"
                            ])) : _createCommentVNode("", true),
                            _createVNode(EgsGameInfoOverlay, {
                                availability: _ctx.item.availability,
                                badge: _ctx.item.ntBadge
                            }, null, 8, [
                                "availability",
                                "badge"
                            ])
                        ], 2),
                        _unref(showPanel) ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass({
                                [_ctx.$style['lobby-egs-games-item-image__panel']]: true,
                                [_ctx.$style['lobby-egs-games-item-image__panel--small']]: _ctx.isDescriptionHidden
                            })
                        }, [
                            _ctx.item.isNew ? (_openBlock(), _createBlock(VBadge, {
                                key: 0,
                                kind: _unref(BadgeKind).SQUARE_LIGHT,
                                label: _ctx.$t('WEB2_FASTGAMES_LOBBY_BADGE_NEW'),
                                class: _normalizeClass(_ctx.$style['lobby-egs-games-item-image__badge'])
                            }, null, 8, [
                                "kind",
                                "label",
                                "class"
                            ])) : _createCommentVNode("", true),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['lobby-egs-games-item-image__title'])
                            }, _toDisplayString(_ctx.item.name), 3),
                            _ctx.isDescriptionHidden ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['lobby-egs-games-item-image__desc'])
                            }, _toDisplayString(_ctx.item?.shortDescription || ''), 3))
                        ], 2)) : _createCommentVNode("", true),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['lobby-egs-games-item-image__provider-badge'])
                        }, [
                            _createCommentVNode("", true)
                        ], 2),
                        !_ctx.isJackpot && (_unref(betRange) || _ctx.item.jackpot) ? (_openBlock(), _createBlock(EgsGameItemBottomBadge, {
                            key: 1,
                            text: _unref(betRange)
                        }, _createSlots({
                            _: 2
                        }, [
                            _ctx.item.jackpot ? {
                                name: "default",
                                fn: _withCtx(()=>[
                                        _createVNode(EgsJackpotAnimatedAmount, _mergeProps(_ctx.item.jackpot.amount, {
                                            "has-background": "",
                                            "jackpot-animation-duration": _ctx.jackpotAnimationDuration,
                                            "is-plain-text": ""
                                        }), null, 16, [
                                            "jackpot-animation-duration"
                                        ])
                                    ]),
                                key: "0"
                            } : void 0
                        ]), 1032, [
                            "text"
                        ])) : _createCommentVNode("", true),
                        _ctx.isJackpot && _ctx.item.jackpot ? (_openBlock(), _createBlock(EgsJackpotAnimatedAmount, _mergeProps({
                            key: 2
                        }, _ctx.item.jackpot.amount, {
                            "has-background": "",
                            "jackpot-animation-duration": _ctx.jackpotAnimationDuration,
                            class: _ctx.$style['lobby-egs-games-item-image__jackpot']
                        }), null, 16, [
                            "jackpot-animation-duration",
                            "class"
                        ])) : _createCommentVNode("", true),
                        _ctx.isJackpot || _ctx.isEditMode ? _createCommentVNode("", true) : (_openBlock(), _createBlock(LobbyEgsGamesItemButtonsHover, {
                            key: 3,
                            "is-demo-available": _unref(isDemoAvailable),
                            onClickPlay: _cache[0] || (_cache[0] = _withModifiers(($event)=>emit('click-play', $event), [
                                "stop",
                                "prevent"
                            ])),
                            onClickDemo: _cache[1] || (_cache[1] = _withModifiers(($event)=>emit('click-demo', $event), [
                                "stop",
                                "prevent"
                            ])),
                            onClick: _cache[2] || (_cache[2] = _withModifiers(($event)=>emit('click', $event), [
                                "stop",
                                "prevent"
                            ]))
                        }, null, 8, [
                            "is-demo-available"
                        ])),
                        _ctx.isEditMode ? (_openBlock(), _createElementBlock("div", {
                            key: 4,
                            class: _normalizeClass(_ctx.$style['lobby-egs-games-item-image__edit'])
                        }, [
                            (_openBlock(), _createBlock(EgsFavorite, {
                                key: 1,
                                "has-background": "",
                                "is-favorite": !_ctx.isRemoved
                            }, null, 8, [
                                "is-favorite"
                            ]))
                        ], 2)) : _createCommentVNode("", true)
                    ], 64)) : (_openBlock(), _createBlock(VSkeletonImage, {
                        key: 1
                    }))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LobbyEgsGamesItemImage'
                ]
            ]);
        };
    }
});
