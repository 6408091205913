import { computed, ref } from 'vue';
import { useLobbySection } from 'web/src/modules/lobby/components/composables/useLobbySection';
export default function useLobbySwiperSection(props, emits) {
    const { lobbyGridBaseProps, headerProps, isHeaderVisible, emitActionClick, emitClickLobbyItem } = useLobbySection(props, emits);
    const swiper = ref();
    const lobbySwiperProps = computed(()=>({
            ...lobbyGridBaseProps.value,
            isSwiperAuto: props.isSwiperAuto,
            rowsNumber: props.rowsNumber,
            isSwiperBlock: props.isSwiperBlock
        }));
    function resetScrollPosition() {
        swiper.value?.resetScrollPosition();
    }
    function emitSwiperScroll(event) {
        emits('swiper-scroll', event);
    }
    return {
        swiper,
        lobbySwiperProps,
        headerProps,
        isHeaderVisible,
        emitActionClick,
        emitClickLobbyItem,
        resetScrollPosition,
        emitSwiperScroll
    };
}
