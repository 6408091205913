import { computed, nextTick, onActivated, onMounted, ref } from 'vue';
import { EgsTopWinnersFilter } from '@leon-hub/api-sdk/src/sdk/sdk';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
import { useLobbySection } from 'web/src/modules/lobby/components/composables/useLobbySection';
import { useI18n } from 'web/src/modules/i18n/composables';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
export default function useLobbyGridSection(props, emits) {
    const { lobbyGridBaseProps, isEdit, headerProps, isSmallHeader, isEditBtnEnabled, isHeaderVisible, emitActionClick, onEditClick, onClickLobbyItem } = useLobbySection(props, emits);
    const { $translate } = useI18n();
    const wrapper = ref();
    const tabs = computed(()=>[
            {
                id: EgsTopWinnersFilter.DAY,
                label: $translate('WEB2_DATEPICKER_DAY').value
            },
            {
                id: EgsTopWinnersFilter.WEEK,
                label: $translate('WEB2_DATEPICKER_WEEK').value
            },
            {
                id: EgsTopWinnersFilter.MONTH,
                label: $translate('WEB2_DATEPICKER_MONTH').value
            },
            {
                id: EgsTopWinnersFilter.ALL_TIME,
                label: $translate('WEB2_DATEPICKER_ALL_TIME').value
            }
        ]);
    const tabsProps = computed(()=>({
            items: tabs.value,
            activeId: props.topWinnersFilter,
            type: TabsType.NORMAL
        }));
    const noGamesTitle = computed(()=>{
        if (props.isEditable) return $translate('WEB2_EGS_MY_GAMES_EMPTY_TTL').value;
        if (props.topWinnersFilter) return $translate('WEB2_EGS_TOP_WINNERS_NO_RESULT_TITLE').value;
    });
    const noGamesDesc = computed(()=>{
        if (props.isEditable) return $translate('WEB2_EGS_MY_GAMES_EMPTY_DESC').value;
    });
    onMounted(scrollOnMounted);
    onActivated(scrollOnMounted);
    async function scrollOnMounted() {
        if (!wrapper.value) await nextTick();
        "1";
        emitScrollTop();
    }
    function onContentScroll() {
        "1";
        emitScrollTop();
    }
    useBusSafeSubscribe(BusEvent.LAYOUT_CONTENT_SCROLL, onContentScroll);
    function emitScrollTop() {
        if (wrapper.value) emitSectionScroll(wrapper.value.getBoundingClientRect());
    }
    function emitSectionScroll(event) {
        emits('section-scroll', event);
    }
    function emitTopWinnersFilterClick(value) {
        emits('top-winners-filter-click', value);
    }
    const currentCounter = computed(()=>props.lobbyItems.length);
    const totalCounter = computed(()=>props.hasNext ? props.total : currentCounter.value);
    const totalTextPlaceholder = computed(()=>({
            count: String(currentCounter.value),
            total: String(totalCounter.value)
        }));
    const firstPageTranslation = $translate('WEB2_EGS_PAGINATION_FIRST_PAGE_COUNTER', totalTextPlaceholder);
    const viewedPageTranslation = $translate('WEB2_EGS_PAGINATION_VIEWED_PAGE_COUNTER', totalTextPlaceholder);
    const reachedEndTranslation = $translate('WEB2_EGS_PAGINATION_REACHED_END');
    const totalText = computed(()=>{
        if (!props.hasNext) return reachedEndTranslation.value;
        if (props.isFirstPage) return firstPageTranslation.value;
        return viewedPageTranslation.value;
    });
    return {
        isEdit,
        wrapper,
        lobbyGridBaseProps,
        headerProps,
        isSmallHeader,
        isEditBtnEnabled,
        isHeaderVisible,
        tabsProps,
        tabs,
        noGamesDesc,
        noGamesTitle,
        totalCounter,
        totalText,
        currentCounter,
        emitActionClick,
        onEditClick,
        emitTopWinnersFilterClick,
        onClickLobbyItem,
        emitScrollTop
    };
}
