import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, unref as _unref, withModifiers as _withModifiers, createBlock as _createBlock, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = [
    "src",
    "alt"
];
const _hoisted_2 = {
    key: 1
};
import { computed, ref } from 'vue';
import { Tag } from '@leon-hub/tags';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbySectionHeader',
    props: {
        isLoading: {
            type: Boolean
        },
        title: {},
        actionLabel: {},
        actionHref: {},
        isModal: {
            type: Boolean
        },
        noBackground: {
            type: Boolean
        },
        isSmall: {
            type: Boolean
        },
        iconUrl: {},
        isHeaderWithHorizontalMargin: {
            type: Boolean
        },
        isGridLayout: {
            type: Boolean
        },
        isCustomHomepage: {
            type: Boolean
        }
    },
    emits: [
        "action-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const isIconLoaded = ref(false);
        function loadIcon() {
            isIconLoaded.value = true;
        }
        const showSkeleton = computed(()=>props.isLoading || props.iconUrl && !isIconLoaded.value);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['lobby-section-header']]: true,
                    [_ctx.$style['lobby-section-header--modal']]: _ctx.isModal,
                    [_ctx.$style['lobby-section-header--no-background']]: _ctx.noBackground,
                    [_ctx.$style['lobby-section-header--small']]: _ctx.isSmall,
                    [_ctx.$style['lobby-section-header--grid-layout']]: _ctx.isGridLayout,
                    [_ctx.$style['lobby-section-header--custom-homepage']]: _ctx.isCustomHomepage
                })
            }, [
                _withDirectives((_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass({
                        [_ctx.$style['lobby-section-header__title']]: true,
                        [_ctx.$style['lobby-section-header__title--with-horizontal-margin']]: _ctx.isHeaderWithHorizontalMargin
                    })
                }, [
                    _renderSlot(_ctx.$slots, "icon", {}, ()=>[
                            _ctx.iconUrl ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: _ctx.iconUrl,
                                alt: _ctx.title,
                                class: _normalizeClass(_ctx.$style['lobby-section-header__icon']),
                                onLoad: loadIcon,
                                onError: loadIcon
                            }, null, 42, _hoisted_1)) : _createCommentVNode("", true)
                        ]),
                    showSkeleton.value ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['lobby-section-header__skeleton'])
                    }, null, 2)) : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.title), 1))
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'egs-lobby-section-header-title'
                        }
                    ]
                ]),
                _renderSlot(_ctx.$slots, "filters", {}, ()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['lobby-section-header__controls'])
                        }, [
                            _renderSlot(_ctx.$slots, "action", {}, ()=>[
                                    _ctx.actionLabel ? (_openBlock(), _createElementBlock(_Fragment, {
                                        key: 0
                                    }, [
                                        _withDirectives((_openBlock(), _createBlock(VButton, {
                                            key: 1,
                                            "is-uppercase": false,
                                            kind: _unref(ButtonKind).BASE,
                                            height: _unref(ButtonHeight).MEDIUM,
                                            label: _ctx.actionLabel,
                                            tag: _ctx.actionHref ? _unref(Tag).A : void 0,
                                            href: _ctx.actionHref,
                                            onClick: _cache[1] || (_cache[1] = _withModifiers(($event)=>emits('action-click'), [
                                                "prevent"
                                            ]))
                                        }, null, 8, [
                                            "kind",
                                            "height",
                                            "label",
                                            "tag",
                                            "href"
                                        ])), [
                                            [
                                                _directive_data_test,
                                                {
                                                    el: 'egs-lobby-section-header-button'
                                                }
                                            ]
                                        ])
                                    ], 64)) : _createCommentVNode("", true)
                                ]),
                            _renderSlot(_ctx.$slots, "navigation")
                        ], 2)
                    ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'LobbySectionHeader'
                ]
            ]);
        };
    }
});
