export default function getSkeletonItems(props, isLoadingLobby) {
    if (!isLoadingLobby) return [];
    if (props.skeletons) return props.skeletons.slice(0, 4).map((skeletonItem)=>({
            skeletonItem,
            isModal: props.isModal,
            noBackground: props.noBackground,
            isHeaderWithHorizontalMargin: props.isHeaderWithHorizontalMargin,
            isFiltersAllowed: props.isFiltersAllowed,
            isCustomHomepage: props.isCustomHomepage
        }));
    return [];
}
