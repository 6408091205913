import { computed } from 'vue';
import { getJackpotGameIdsFromLobbyItems, getLobbyTopMatchesItems } from 'web/src/modules/lobby/store/utils';
import { useComponentSyncState } from 'web/src/modules/core/composables';
import { EgsJackpotsSyncStateTimerName, SportlineLobbySyncStateTimerName } from 'web/src/modules/egs/constants';
import useLobbyJackpotsStore from 'web/src/modules/lobby/store/useLobbyJackpotsStore';
import useLobbySportlineUpdateStore from 'web/src/modules/lobby/store/useLobbySportlineUpdateStore';
export function useLobbySyncState(lobbyItems) {
    const { updateLobbyJackpotGamesAmount } = useLobbyJackpotsStore();
    const { updateLobbySportlineTopMatches } = useLobbySportlineUpdateStore();
    const hasJackpotsUpdatableItems = computed(()=>getJackpotGameIdsFromLobbyItems(lobbyItems.value).length > 0);
    const hasSportlineMatchesItems = computed(()=>getLobbyTopMatchesItems(lobbyItems.value).length > 0);
    useComponentSyncState(()=>updateLobbyJackpotGamesAmount(lobbyItems.value), EgsJackpotsSyncStateTimerName, {
        condition: hasJackpotsUpdatableItems
    });
    useComponentSyncState(()=>updateLobbySportlineTopMatches(lobbyItems.value), SportlineLobbySyncStateTimerName, {
        condition: hasSportlineMatchesItems
    });
}
