import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps } from "vue";
import { ref } from 'vue';
import { LobbyItemType } from '@leon-hub/api-sdk';
import { useLobbySectionContainer } from 'web/src/modules/lobby/components/LobbySectionContainer/composables';
import LobbySwiperSection from 'web/src/modules/lobby/components/LobbySwiperSection/LobbySwiperSection.vue';
import LobbyAllTopWinnersSection from 'web/src/modules/lobby/components/LobbyAllTopWinnersSection/LobbyAllTopWinnersSection.vue';
import LobbyGridSection from 'web/src/modules/lobby/components/LobbyGridSection/LobbyGridSection.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbySectionContainer',
    props: {
        lobbyItem: {},
        skeletonItem: {},
        isModal: {
            type: Boolean
        },
        noBackground: {
            type: Boolean
        },
        shouldReplaceGameRoutes: {
            type: Boolean
        },
        jackpotRequestInterval: {},
        actionHref: {},
        isHeaderWithHorizontalMargin: {
            type: Boolean
        },
        lazyLoadPagesCounter: {
            default: 0
        },
        isFiltersAllowed: {
            type: Boolean
        },
        isCustomHomepage: {
            type: Boolean
        }
    },
    emits: [
        "click-lobby-item",
        "action-click",
        "top-winners-filter-click",
        "swiper-scroll",
        "section-scroll",
        "delete-items"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { isSwiper, typeId, lobbySectionId, isSkeletonItemsShown, lobbySwiperProps, lobbySectionBaseProps, lobbySwiperBackground, allTopWinnersSports, allTopWinnersEgs, allTopWinnersTab, emitActionClick, emitSwiperScroll, emitSectionScroll, emitTopWinnersFilterClick, emitClickLobbyItem, emitDeleteItems } = useLobbySectionContainer(props, emits);
        const swiper = ref();
        function resetScrollPosition() {
            swiper.value?.resetScrollPosition();
        }
        __expose({
            resetScrollPosition
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            return _unref(typeId) === _unref(LobbyItemType).ALL_TOP_WINNERS ? _withDirectives((_openBlock(), _createBlock(LobbyAllTopWinnersSection, {
                key: 0,
                "is-custom-homepage": _ctx.isCustomHomepage,
                class: _normalizeClass(_ctx.$style['lobby-section--all-top-winners']),
                "header-icon": _unref(lobbySectionBaseProps).headerIcon,
                "header-title": _unref(lobbySectionBaseProps).headerName,
                "top-sports": _unref(allTopWinnersSports),
                "top-games": _unref(allTopWinnersEgs),
                tab: _unref(allTopWinnersTab)
            }, null, 8, [
                "is-custom-homepage",
                "class",
                "header-icon",
                "header-title",
                "top-sports",
                "top-games",
                "tab"
            ])), [
                [
                    _directive_data_test,
                    {
                        el: 'egs-lobby-section',
                        'type-id': _unref(typeId),
                        'section-id': _unref(lobbySectionId),
                        'is-skeleton': _unref(isSkeletonItemsShown)
                    }
                ]
            ]) : _unref(isSwiper) ? _withDirectives((_openBlock(), _createBlock(LobbySwiperSection, _mergeProps({
                key: 1,
                ref_key: "swiper",
                ref: swiper
            }, _unref(lobbySwiperProps), {
                background: _unref(lobbySwiperBackground),
                onActionClick: _unref(emitActionClick),
                onSwiperScroll: _unref(emitSwiperScroll),
                onSectionScroll: _unref(emitSectionScroll),
                onTopWinnersFilterClick: _unref(emitTopWinnersFilterClick),
                onClickLobbyItem: _unref(emitClickLobbyItem),
                onDeleteItems: _unref(emitDeleteItems)
            }), null, 16, [
                "background",
                "onActionClick",
                "onSwiperScroll",
                "onSectionScroll",
                "onTopWinnersFilterClick",
                "onClickLobbyItem",
                "onDeleteItems"
            ])), [
                [
                    _directive_data_test,
                    {
                        el: 'egs-lobby-section',
                        'type-id': _unref(typeId),
                        'section-id': _unref(lobbySectionId),
                        'is-skeleton': _unref(isSkeletonItemsShown)
                    }
                ]
            ]) : _withDirectives((_openBlock(), _createBlock(LobbyGridSection, _mergeProps({
                key: 2
            }, _unref(lobbySectionBaseProps), {
                onActionClick: _unref(emitActionClick),
                onSwiperScroll: _unref(emitSwiperScroll),
                onSectionScroll: _unref(emitSectionScroll),
                onTopWinnersFilterClick: _unref(emitTopWinnersFilterClick),
                onClickLobbyItem: _unref(emitClickLobbyItem),
                onDeleteItems: _unref(emitDeleteItems)
            }), null, 16, [
                "onActionClick",
                "onSwiperScroll",
                "onSectionScroll",
                "onTopWinnersFilterClick",
                "onClickLobbyItem",
                "onDeleteItems"
            ])), [
                [
                    _directive_data_test,
                    {
                        el: 'egs-lobby-section',
                        'type-id': _unref(typeId),
                        'section-id': _unref(lobbySectionId),
                        'is-skeleton': _unref(isSkeletonItemsShown)
                    }
                ]
            ]);
        };
    }
});
