import { computed, nextTick, ref } from 'vue';
import { useRouter } from 'vue-router';
import { EgsGameType } from '@leon-hub/api-sdk/src/sdk/sdk';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { useEgsNavigation } from 'web/src/modules/egs/submodules/navigation/composable';
import { getImageProperties, getBetRange } from './utils';
export default function useLobbyEgsGamesItemImage(props) {
    const router = useRouter();
    const { getEgsGameHref } = useEgsNavigation(router);
    const image = ref();
    const isImageLoadError = ref(false);
    const isImageLoaded = ref(false);
    const imageProperties = computed(()=>getImageProperties(isImageLoadError.value, props));
    const brief = computed(()=>props.item?.shortDescription || '');
    const isDemoAvailable = computed(()=>!!props.item?.hasDemo && props.item?.typeId !== EgsGameType.FASTGAMES);
    const showPanel = computed(()=>{
        if (process.env.VUE_APP_PRERENDER) return !!props.isPanelVisible;
        return !!(props.isPanelVisible && (isImageLoaded.value || isImageLoadError.value));
    });
    const href = computed(()=>props.item ? getEgsGameHref(props.item) : void 0);
    const betRange = computed(()=>getBetRange(props.item?.limits));
    function onLazyImageMounted() {
        if (props.hasImageForceLoad) image.value?.forceShowImage();
    }
    async function onImageLoaded() {
        await nextTick();
        await nextAnimationFrame();
        isImageLoaded.value = true;
    }
    function onImageError() {
        isImageLoadError.value = true;
    }
    return {
        href,
        image,
        imageProperties,
        onLazyImageMounted,
        onImageError,
        onImageLoaded,
        betRange,
        showPanel,
        brief,
        isDemoAvailable
    };
}
