import { defineStore } from 'pinia';
import { toRef } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { getHeadlineMatches, getHeadlineMatchesChanges } from 'web/src/modules/sportline/utils/api';
import { isGetHeadlineMatchesChangesResponseCheck, isGetMergedHeadlineMatchesChangesResponseCheck, isGetMergedHeadlineMatchesResponseCheck } from 'web/src/modules/sportline/guards/rest';
import { filterCorruptedEvents, logCorruptedEventsIds } from 'web/src/modules/sportline/utils/response';
import { DetailsAdapter } from 'web/src/modules/sportline/utils/rest';
import useRootStore from 'web/src/modules/core/store/useRootStore';
import { useSportlineTopEventsSettings } from 'web/src/modules/sportline/composables/settings';
import { getLobbyTopMatchesItems } from './utils';
const useLobbySportlineUpdateStore = defineStore('lobby-sportline-update', ()=>{
    const cTag = toRef(useRootStore(), 'cTag');
    const settingsStore = useSportlineSettingsStore();
    const parseSportlineSettings = toRef(settingsStore, 'parseSportlineSettings');
    const { flags } = useSportlineTopEventsSettings();
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async function updateLobbySportlineTopMatches(lobbyItems) {
        const sportlineTopMatchesItems = getLobbyTopMatchesItems(lobbyItems);
        if (sportlineTopMatchesItems.length > 0) {
            let result;
            const isMakeUpdateRequest = !!sportlineTopMatchesItems[0].vtag && 'invalid' !== sportlineTopMatchesItems[0].vtag;
            if (isMakeUpdateRequest) result = await getHeadlineMatchesChanges({
                ctag: cTag.value,
                allVtag: sportlineTopMatchesItems[0].vtag,
                silent: true,
                flags: flags.value
            });
            const isInvalidRequest = result?.events?.vtag === 'invalid';
            if (!isMakeUpdateRequest || isInvalidRequest) result = await getHeadlineMatches({
                ctag: cTag.value,
                silent: true,
                flagsString: sportlineTopMatchesItems[0].flags,
                merged: true
            });
            let newVtag = '';
            const newEvents = [];
            let responseEvents = [];
            let doUpdate = false;
            if (result && isGetHeadlineMatchesChangesResponseCheck(result) && isGetMergedHeadlineMatchesChangesResponseCheck(result)) {
                newVtag = result.events.vtag || '';
                responseEvents = result.events.data;
                doUpdate = true;
            }
            if (result && !isGetHeadlineMatchesChangesResponseCheck(result) && isGetMergedHeadlineMatchesResponseCheck(result)) {
                newVtag = result.events.vtag || '';
                responseEvents = result.events.events;
                doUpdate = true;
            }
            if (doUpdate) {
                for (const event of filterCorruptedEvents(responseEvents, logCorruptedEventsIds))newEvents.push(new DetailsAdapter(event, parseSportlineSettings.value).sportEventDetails);
                for (const lobbyItem of sportlineTopMatchesItems){
                    lobbyItem.events = newEvents;
                    lobbyItem.vtag = newVtag;
                }
            }
        }
    }
    return {
        updateLobbySportlineTopMatches
    };
});
export default useLobbySportlineUpdateStore;
