import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { EgsTopWinnersFilter, LobbyGridType, LobbyItemType, LobbyItemWidget, LobbyTitleType } from '@leon-hub/api-sdk';
import { isFavoriteLobbyItem, isJackpotWidget, isLobbyTopWinnersItem, isSwiperWidget } from 'web/src/modules/lobby/utils';
import { useTheme } from 'web/src/modules/theme/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
const defaultItemsCounter = 24;
export default function useLobbySectionContainer(props, emits) {
    const { $translate } = useI18n();
    const formatMoney = useFormatMoney();
    const { isLight } = useTheme();
    useRoute();
    const typeId = computed(()=>{
        const type = props.lobbyItem?.typeId ?? props.skeletonItem?.typeId ?? LobbyItemType.GAMES_CATEGORY;
        if (type === LobbyItemType.JACKPOTS) return LobbyItemType.GAMES_CATEGORY;
        return type;
    });
    const widgetId = computed(()=>props.lobbyItem?.widgetId ?? props.skeletonItem?.widgetId ?? LobbyItemWidget.LIST);
    // eslint-disable-next-line max-len
    const titleType = computed(()=>props.lobbyItem?.titleType ?? props.skeletonItem?.titleType ?? LobbyTitleType.DEFAULT);
    const isSwiper = computed(()=>isSwiperWidget(widgetId.value));
    // eslint-disable-next-line max-len
    const gridType = computed(()=>props.lobbyItem?.widgetOptions.gridType ?? props.skeletonItem?.gridType ?? LobbyGridType.DEFAULT);
    // eslint-disable-next-line max-len
    const swiperRowsNumber = computed(()=>props.lobbyItem?.widgetOptions.swiperRowsNumber || props.skeletonItem?.swiperRowsNumber || 1);
    // eslint-disable-next-line max-len
    const isSwiperBlock = computed(()=>props.lobbyItem?.widgetOptions.isSwiperBlock ?? !!props.skeletonItem?.isSwiperBlock);
    const isEditable = computed(()=>isFavoriteLobbyItem(props.lobbyItem));
    const lobbySectionId = computed(()=>{
        if (!props.lobbyItem) return '';
        switch(props.lobbyItem.typeId){
            case LobbyItemType.GAMES_CATEGORY:
            case LobbyItemType.TOP_WINNERS:
                return props.lobbyItem.category.id;
            default:
                return '';
        }
    });
    const isJackpot = computed(()=>isJackpotWidget(widgetId.value));
    const gamesSkeletonItemsCounter = computed(()=>{
        if (widgetId.value === LobbyItemWidget.BIG_SMALL_ROWS_LIST) return 6;
        if (isSwiper.value) {
            if (isJackpot.value) return 5;
            return 10;
        }
        return defaultItemsCounter;
    });
    const skeletonItemsCounter = computed(()=>{
        let number = defaultItemsCounter;
        switch(typeId.value){
            case LobbyItemType.GAMES_CATEGORY:
                number = gamesSkeletonItemsCounter.value;
                break;
            case LobbyItemType.TOP_WINNERS:
            case LobbyItemType.PROMOTIONS:
                if (isSwiper.value) number = 5;
                break;
            case LobbyItemType.BANNERS:
                number = 2;
                break;
            case LobbyItemType.SPORTLINE_TOP_MATCHES:
                number = 3;
                break;
            case LobbyItemType.GROUPS:
                if (isSwiper.value) number = 10;
                break;
            default:
                break;
        }
        return number * swiperRowsNumber.value;
    });
    const isSkeletonItemsShown = computed(()=>{
        if (!props.lobbyItem) return true;
        if (props.lobbyItem.typeId === LobbyItemType.TOP_WINNERS) {
            isLobbyTopWinnersItem(props.lobbyItem);
            return !!props.lobbyItem.showSkeleton;
        }
        return false;
    });
    const lobbyItems = computed(()=>{
        if (!props.lobbyItem) return Array.from({
            length: skeletonItemsCounter.value
        }, ()=>void 0);
        switch(props.lobbyItem.typeId){
            case LobbyItemType.PROMOTIONS:
                return props.lobbyItem.promotions;
            case LobbyItemType.GROUPS:
                return props.lobbyItem.groups;
            case LobbyItemType.GAMES_CATEGORY:
                return props.lobbyItem.games;
            case LobbyItemType.TOP_WINNERS:
                isLobbyTopWinnersItem(props.lobbyItem);
                if (props.lobbyItem.showSkeleton) return Array.from({
                    length: skeletonItemsCounter.value
                }, ()=>void 0);
                return props.lobbyItem.winners;
            case LobbyItemType.BANNERS:
                return props.lobbyItem.banners;
            case LobbyItemType.ALL_TOP_WINNERS:
                return [];
            case LobbyItemType.SPORTLINE_TOP_MATCHES:
                return props.lobbyItem.events;
            default:
                throw new Error('Unknown LobbyItemType');
        }
    });
    const isItemNameVisible = computed(()=>{
        if (gridType.value === LobbyGridType.SMALL) return false;
        if (widgetId.value === LobbyItemWidget.BIG_SMALL_ROWS_LIST || widgetId.value === LobbyItemWidget.SQUARE_ROWS_LIST) return false;
        if (typeId.value !== LobbyItemType.GAMES_CATEGORY) return false;
        return true;
    });
    const headerIcon = computed(()=>{
        if (isLight.value && props.lobbyItem?.iconUrl?.light) return props.lobbyItem.iconUrl.light;
        return props.lobbyItem?.iconUrl?.dark || void 0;
    });
    const showSkeleton = computed(()=>!props.lobbyItem);
    const topWinnersFilter = computed(()=>{
        if (props.lobbyItem?.typeId === LobbyItemType.TOP_WINNERS) return props.lobbyItem.filter;
        if (typeId.value === LobbyItemType.TOP_WINNERS) return EgsTopWinnersFilter.MONTH;
    });
    const hasNext = computed(()=>{
        if (props.lobbyItem?.typeId === LobbyItemType.GAMES_CATEGORY || props.lobbyItem?.typeId === LobbyItemType.TOP_WINNERS) return !!props.lobbyItem.next;
        return false;
    });
    const total = computed(()=>{
        if (props.lobbyItem?.typeId === LobbyItemType.GAMES_CATEGORY || props.lobbyItem?.typeId === LobbyItemType.TOP_WINNERS) return props.lobbyItem.next?.total ?? void 0;
    });
    const isFirstPage = computed(()=>{
        if (props.lobbyItem?.typeId === LobbyItemType.GAMES_CATEGORY || props.lobbyItem?.typeId === LobbyItemType.TOP_WINNERS) return (props.lobbyItem.next?.offset || 0) <= (props.lobbyItem.next?.limit || 0);
        return true;
    });
    const lobbySectionBaseProps = computed(()=>({
            lobbyItems: lobbyItems.value,
            widgetId: widgetId.value,
            gridType: gridType.value,
            lobbyItemType: typeId.value,
            actionLabel: props.lobbyItem?.allButtonText,
            actionHref: props.actionHref,
            headerName: props.lobbyItem?.name || '',
            isModal: props.isModal,
            jackpotAnimationDuration: props.jackpotRequestInterval,
            hasNext: hasNext.value,
            showSkeleton: showSkeleton.value,
            topWinnersFilter: topWinnersFilter.value,
            isItemNameVisible: isItemNameVisible.value,
            isEditable: isEditable.value,
            headerIcon: headerIcon.value,
            isHeaderWithHorizontalMargin: props.isHeaderWithHorizontalMargin,
            titleType: titleType.value,
            total: total.value,
            isFirstPage: isFirstPage.value,
            isFiltersAllowed: props.isFiltersAllowed,
            noBackground: props.noBackground,
            isCustomHomepage: props.isCustomHomepage,
            isMoreLoading: props.lobbyItem?.typeId === LobbyItemType.GAMES_CATEGORY && props.lobbyItem.isLoading,
            isLoadMoreButtonVisible: props.lazyLoadPagesCounter > 0 && props.lobbyItem?.typeId === LobbyItemType.GAMES_CATEGORY && (!props.lobbyItem.loadingCounter || !!props.lobbyItem.isLoading || props.lobbyItem.loadingCounter % props.lazyLoadPagesCounter === 0)
        }));
    const lobbySwiperProps = computed(()=>({
            ...lobbySectionBaseProps.value,
            rowsNumber: swiperRowsNumber.value,
            isSwiperAuto: props.lobbyItem?.widgetOptions.isSwiperAuto ?? false,
            isSwiperBlock: isSwiperBlock.value
        }));
    const lobbySwiperBackground = computed(()=>{});
    function emitActionClick() {
        emits('action-click');
    }
    function emitSwiperScroll(event) {
        emits('swiper-scroll', event);
    }
    function emitSectionScroll(event) {
        emits('section-scroll', event);
    }
    function emitTopWinnersFilterClick(value) {
        emits('top-winners-filter-click', value);
    }
    function emitClickLobbyItem(data) {
        emits('click-lobby-item', data);
    }
    function emitDeleteItems(data) {
        emits('delete-items', data);
    }
    const allTopWinnersSports = computed(()=>{
        if (props.lobbyItem?.typeId !== LobbyItemType.ALL_TOP_WINNERS) return [];
        return props.lobbyItem.sportTopWinners?.winners.map((item)=>({
                name: item.sportBetEvents.map((sportEvent)=>sportEvent.eventName).join(', ') || $translate('WEB2_SPORT_TOP_WINNERS_COMBI_NAME').value,
                coefficient: item.odds ? String(item.odds) : void 0,
                userName: item.player.name,
                win: formatMoney(item.winAmount.value, {
                    currency: item.winAmount.currency
                })
            })) ?? [];
    });
    const allTopWinnersEgs = computed(()=>{
        if (props.lobbyItem?.typeId !== LobbyItemType.ALL_TOP_WINNERS) return [];
        return props.lobbyItem.egsTopWinners?.winners.map((item)=>({
                name: item.egsGame.name,
                userName: item.player.name,
                win: formatMoney(item.winAmount.value, {
                    currency: item.winAmount.currency
                }),
                iconSrc: item.egsGame.categories[0]?.iconUrl ?? void 0
            })) ?? [];
    });
    const allTopWinnersTab = computed(()=>{
        if (props.lobbyItem?.typeId !== LobbyItemType.ALL_TOP_WINNERS) return;
        return props.lobbyItem.primaryTab ?? void 0;
    });
    return {
        lobbySwiperBackground,
        lobbySwiperProps,
        lobbySectionBaseProps,
        allTopWinnersSports,
        allTopWinnersEgs,
        typeId,
        lobbySectionId,
        isSkeletonItemsShown,
        isSwiper,
        allTopWinnersTab,
        emitActionClick,
        emitSwiperScroll,
        emitSectionScroll,
        emitTopWinnersFilterClick,
        emitClickLobbyItem,
        emitDeleteItems
    };
}
