import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
    key: 0
};
import { VIcon } from '@components/v-icon';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VSkeletonImage from 'web/src/components/Skeleton/VSkeletonImage/VSkeletonImage.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import { useLobbyEgsTopWinnerItem } from 'web/src/modules/lobby/components/LobbyEgsTopWinnerItem/composables';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbyEgsTopWinnerItem',
    props: {
        item: {},
        isSmall: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { imageProperties, iconProperties, playButtonProperties, ntBadgeProperties, moneyAmount, href } = useLobbyEgsTopWinnerItem(props);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(href) ? 'a' : 'div'), {
                href: _unref(href),
                class: _normalizeClass({
                    [_ctx.$style['lobby-egs-top-winner-item']]: true,
                    [_ctx.$style['lobby-egs-top-winner-item--small']]: _ctx.isSmall
                }),
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event)=>emits('click', $event), [
                    "prevent"
                ]))
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['lobby-egs-top-winner-item__image-wrapper'])
                        }, [
                            _ctx.item ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _createVNode(VImage, _mergeProps(_unref(imageProperties), {
                                    "object-fit": _unref(ObjectFitOption).COVER,
                                    "is-lazy": "",
                                    "show-loader": "",
                                    class: _ctx.$style['lobby-egs-top-winner-item__image']
                                }), null, 16, [
                                    "object-fit",
                                    "class"
                                ]),
                                (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['lobby-egs-top-winner-item__hover'])
                                }, [
                                    _createVNode(VButton, _mergeProps(_unref(playButtonProperties), {
                                        class: _ctx.$style['lobby-egs-top-winner-item__hover-btn'],
                                        onClick: _cache[0] || (_cache[0] = _withModifiers(($event)=>emits('click', $event), [
                                            "stop",
                                            "prevent"
                                        ]))
                                    }), null, 16, [
                                        "class"
                                    ])
                                ], 2)),
                                _ctx.item?.egsGame.ntBadge ? (_openBlock(), _createBlock(VBadge, _mergeProps({
                                    key: 1
                                }, _unref(ntBadgeProperties), {
                                    class: _ctx.$style['lobby-egs-top-winner-item__nt-badge']
                                }), null, 16, [
                                    "class"
                                ])) : _createCommentVNode("", true)
                            ], 64)) : _withDirectives((_openBlock(), _createBlock(VSkeletonImage, {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['lobby-egs-top-winner-item__skeleton-image'])
                            }, null, 8, [
                                "class"
                            ])), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'lobby-egs-top-winner-skeleton-image'
                                    }
                                ]
                            ])
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['lobby-egs-top-winner-item__right'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass({
                                    [_ctx.$style['lobby-egs-top-winner-item__name']]: true,
                                    [_ctx.$style['lobby-egs-top-winner-item__name--skeleton']]: !_ctx.item
                                })
                            }, [
                                _ctx.item ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.item.egsGame.name), 1)) : _createCommentVNode("", true)
                            ], 2),
                            _ctx.isSmall ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass({
                                    [_ctx.$style['lobby-egs-top-winner-item__group']]: true,
                                    [_ctx.$style['lobby-egs-top-winner-item__group--skeleton']]: !_ctx.item
                                })
                            }, [
                                _ctx.item ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 0
                                }, [
                                    _createTextVNode(_toDisplayString(_ctx.item.egsGame.group.name), 1)
                                ], 64)) : _createCommentVNode("", true)
                            ], 2)),
                            _createElementVNode("div", {
                                class: _normalizeClass({
                                    [_ctx.$style['lobby-egs-top-winner-item__amount']]: true,
                                    [_ctx.$style['lobby-egs-top-winner-item__amount--skeleton']]: !_ctx.item
                                })
                            }, _toDisplayString(_unref(moneyAmount)), 3),
                            _createElementVNode("div", {
                                class: _normalizeClass({
                                    [_ctx.$style['lobby-egs-top-winner-item__player']]: true,
                                    [_ctx.$style['lobby-egs-top-winner-item__player--skeleton']]: !_ctx.item
                                })
                            }, [
                                _ctx.item ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 0
                                }, [
                                    _createVNode(_unref(VIcon), _mergeProps({
                                        class: _ctx.$style['lobby-egs-top-winner-item__player-icon']
                                    }, _unref(iconProperties)), null, 16, [
                                        "class"
                                    ]),
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['lobby-egs-top-winner-item__player-label'])
                                    }, _toDisplayString(_ctx.item.player.name), 3)
                                ], 64)) : _createCommentVNode("", true)
                            ], 2)
                        ], 2)
                    ]),
                _: 1
            }, 8, [
                "href",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'LobbyEgsTopWinnerItem'
                ]
            ]);
        };
    }
});
