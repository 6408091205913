import { computed } from 'vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useLobbyEgsGamesItemButtonsHover() {
    const { $translate } = useI18n();
    const playButtonProperties = computed(()=>({
            rounded: true,
            kind: ButtonKind.PRIMARY,
            label: $translate('BUTTON_PLAY').value
        }));
    const demoButtonProperties = computed(()=>({
            rounded: true,
            isUppercase: false,
            kind: ButtonKind.SECONDARY,
            height: ButtonHeight.TINY,
            label: $translate('WEB2_EGS_BUTTON_DEMO').value
        }));
    return {
        playButtonProperties,
        demoButtonProperties
    };
}
