import { computed } from 'vue';
import { LobbyGridType, LobbyItemType, LobbyItemWidget } from '@leon-hub/api-sdk/src/sdk/sdk';
import { LobbyGridLayoutType, LobbyItemLayoutType } from 'web/src/modules/lobby/enums';
import { isEgsGame, isEgsGroup, isPromotion } from 'web/src/modules/lobby/utils';
import { getModalGamesLobbyGridType, getNoBackgroundGamesLobbyGridType } from './utils';
export default function useLobbyGrid(props, emits) {
    const bigSmallRowsGamesGridType = computed(()=>{
        "1";
        return props.isModal ? LobbyGridLayoutType.BIG_SMALL_SMALL : LobbyGridLayoutType.BIG_SMALL_LARGE;
    });
    const jackpotWinnerGridType = computed(()=>{
        "1";
        return props.isModal ? LobbyGridLayoutType.COLUMN_1 : LobbyGridLayoutType.COLUMN_JACKPOT;
    });
    const squareGamesGridType = computed(()=>{
        "1";
        {
            const modalGridType = getModalGamesLobbyGridType(props);
            if (modalGridType) return modalGridType;
        }
        if (props.lobbyGridType === LobbyGridType.SMALL) return LobbyGridLayoutType.COLUMN_SQUARE_GAME_SMALL;
        if (props.lobbyGridType === LobbyGridType.LARGE) return LobbyGridLayoutType.COLUMN_SQUARE_GAME_LARGE;
        return LobbyGridLayoutType.COLUMN_SQUARE_GAME_DEFAULT;
    });
    const defaultGamesGridType = computed(()=>{
        "1";
        {
            const modalGridType = getModalGamesLobbyGridType(props);
            if (modalGridType) return modalGridType;
        }
        const noBackgroundGridType = getNoBackgroundGamesLobbyGridType(props);
        if (noBackgroundGridType) return noBackgroundGridType;
        if (props.lobbyGridType === LobbyGridType.SMALL) return LobbyGridLayoutType.COLUMN_GAME_SMALL;
        if (props.lobbyGridType === LobbyGridType.LARGE) return LobbyGridLayoutType.COLUMN_GAME_LARGE;
        return LobbyGridLayoutType.COLUMN_GAME_DEFAULT;
    });
    const gamesGridType = computed(()=>{
        switch(props.widgetId){
            case LobbyItemWidget.BIG_SMALL_ROWS_LIST:
                return bigSmallRowsGamesGridType.value;
            case LobbyItemWidget.SQUARE_ROWS_LIST:
                return LobbyGridLayoutType.SQUARE_ROW;
            case LobbyItemWidget.JACKPOT_LIST:
            case LobbyItemWidget.JACKPOT_SWIPE_LIST:
                return jackpotWinnerGridType.value;
            case LobbyItemWidget.SQUARE_LIST:
            case LobbyItemWidget.SQUARE_SWIPE_LIST:
                return squareGamesGridType.value;
            case LobbyItemWidget.LIST:
            case LobbyItemWidget.SWIPE_LIST:
            default:
                return defaultGamesGridType.value;
        }
    });
    const promoGridType = computed(()=>{
        "1";
        return props.isModal ? LobbyGridLayoutType.COLUMN_1 : LobbyGridLayoutType.COLUMN_PROMOTION;
    });
    const bannerGridType = computed(()=>{
        "1";
        return props.isModal ? LobbyGridLayoutType.COLUMN_1 : LobbyGridLayoutType.COLUMN_2;
    });
    const groupGridType = computed(()=>{
        "1";
        return props.isModal ? LobbyGridLayoutType.COLUMN_3 : LobbyGridLayoutType.COLUMN_GROUP;
    });
    const topWinnerGridType = computed(()=>{
        "1";
        return props.isModal ? LobbyGridLayoutType.COLUMN_1 : LobbyGridLayoutType.COLUMN_TOP_WINNER;
    });
    const sportlineTopMatchGridType = computed(()=>{
        "1";
        return props.isModal ? LobbyGridLayoutType.COLUMN_1 : LobbyGridLayoutType.COLUMN_SPORTLINE_TOP_MATCH;
    });
    const gridType = computed(()=>{
        switch(props.lobbyItemType){
            case LobbyItemType.PROMOTIONS:
                return promoGridType.value;
            case LobbyItemType.BANNERS:
                return bannerGridType.value;
            case LobbyItemType.SPORTLINE_TOP_MATCHES:
                return sportlineTopMatchGridType.value;
            case LobbyItemType.TOP_WINNERS:
                return topWinnerGridType.value;
            case LobbyItemType.JACKPOTS:
                return jackpotWinnerGridType.value;
            case LobbyItemType.GROUPS:
                return groupGridType.value;
            case LobbyItemType.GAMES_CATEGORY:
            default:
                return gamesGridType.value;
        }
    });
    function getGamesLobbyItemLayoutType(index) {
        switch(gridType.value){
            case LobbyGridLayoutType.BIG_SMALL_LARGE:
                return index < 2 ? LobbyItemLayoutType.GAME_BIG_ROW : LobbyItemLayoutType.GAME_SQUARE;
            case LobbyGridLayoutType.BIG_SMALL_SMALL:
                return index % 3 === 0 ? LobbyItemLayoutType.GAME_BIG_ROW : LobbyItemLayoutType.GAME_SQUARE;
            case LobbyGridLayoutType.SQUARE_ROW:
            case LobbyGridLayoutType.COLUMN_SQUARE_GAME_DEFAULT:
            case LobbyGridLayoutType.COLUMN_SQUARE_GAME_SMALL:
            case LobbyGridLayoutType.COLUMN_SQUARE_GAME_LARGE:
            case LobbyGridLayoutType.COLUMN_MODAL_GAME_SQUARE_SMALL:
            case LobbyGridLayoutType.COLUMN_NO_BG_GAME_SQUARE_SMALL:
                return LobbyItemLayoutType.GAME_SQUARE;
            case LobbyGridLayoutType.COLUMN_JACKPOT:
                return LobbyItemLayoutType.GAME_JACKPOT;
            default:
                return LobbyItemLayoutType.GAME_DEFAULT;
        }
    }
    function getItemKey(item, index) {
        if (isEgsGame(item, props.lobbyItemType) || isEgsGroup(item, props.lobbyItemType)) return item.id;
        if (isPromotion(item, props.lobbyItemType)) return item.url;
        return String(index);
    }
    function getLobbyItemProps(item, index) {
        return {
            item,
            lobbyItemType: props.lobbyItemType,
            widgetId: props.widgetId,
            layoutType: getLobbyItemLayoutType(index),
            jackpotAnimationDuration: props.jackpotAnimationDuration,
            isNameVisible: props.isItemNameVisible,
            isEditMode: props.isEditMode,
            isRemoved: isItemRemoved(item)
        };
    }
    function isItemRemoved(item) {
        if (!item) return false;
        if (props.lobbyItemType === LobbyItemType.GAMES_CATEGORY) {
            isEgsGame(item, props.lobbyItemType);
            return !!props.gameIdsToRemove && props.gameIdsToRemove.includes(item.id);
        }
        return false;
    }
    function getLobbyItemLayoutType(index) {
        switch(props.lobbyItemType){
            case LobbyItemType.GAMES_CATEGORY:
                return getGamesLobbyItemLayoutType(index);
            case LobbyItemType.PROMOTIONS:
                return LobbyItemLayoutType.PROMOTION;
            case LobbyItemType.BANNERS:
                return LobbyItemLayoutType.BANNER;
            case LobbyItemType.SPORTLINE_TOP_MATCHES:
                return LobbyItemLayoutType.SPORTLINE_TOP_MATCH;
            case LobbyItemType.GROUPS:
                return LobbyItemLayoutType.GROUP;
            case LobbyItemType.TOP_WINNERS:
                return props.widgetId === LobbyItemWidget.SWIPE_LIST ? LobbyItemLayoutType.TOP_WINNER_SMALL : LobbyItemLayoutType.TOP_WINNER_BIG;
            default:
                return LobbyItemLayoutType.GAME_DEFAULT;
        }
    }
    function emitClickLobbyItem(data) {
        emits('click-lobby-item', data);
    }
    return {
        getItemKey,
        gridType,
        getLobbyItemProps,
        emitClickLobbyItem
    };
}
