export function hexToRGB(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    if (alpha) return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    return `rgb(${r}, ${g}, ${b})`;
}
export function rgbaColor(color) {
    let alpha = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : '0.15';
    if (!color) return '';
    if (color.startsWith('rgb')) {
        if (color.startsWith('rgba')) return color;
        return `rgba${color.slice(3)}`.replace(/\)$/, `, ${alpha})`);
    }
    return hexToRGB(color, alpha);
}
