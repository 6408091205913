import { computed, nextTick, ref, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { LobbyGameType, lobbyTypeLiveCasino, lobbyTypeSlots } from '@leon-hub/api-sdk';
import { useDebounce } from '@leon-hub/debounce';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useEgsGroupsStore } from 'web/src/modules/egs/submodules/groups/store';
import { filterBlockedGroups } from 'web/src/modules/egs/utils';
import { useEgsApi } from 'web/src/modules/egs/composables';
export default function useLobbySearchGroups() {
    let allGroupsId = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
    const searchGroups = ref(null);
    const searchText = ref('');
    const { $translate } = useI18n();
    const router = useRouter();
    const configStore = useSiteConfigStore();
    const searchMinCharsSize = toRef(configStore, 'searchMinCharsSize');
    const currentGroups = toRef(useEgsGroupsStore(), 'currentGroups');
    const isSearchResult = computed(()=>searchText.value.length >= searchMinCharsSize.value);
    const isSearchInProgress = computed(()=>isSearchResult.value && null === searchGroups.value);
    const isNoSearchResults = computed(()=>isSearchResult.value && !!searchGroups.value && 0 === searchGroups.value.length);
    const isLive = computed(()=>router.getName() === CasinoRouteName.CASINO_LIVE_GROUPS || router.getName() === CasinoRouteName.CASINO_LIVE_LOBBY);
    const allGroups = computed(()=>[
            {
                id: allGroupsId,
                name: $translate('WEB2_ALL_PROVIDERS').value,
                url: allGroupsId,
                iconUrls: {},
                type: isLive.value ? LobbyGameType.LIVE : LobbyGameType.SLOTS
            },
            ...filterBlockedGroups(currentGroups.value)
        ]);
    const list = computed(()=>{
        if (isSearchResult.value) return filterBlockedGroups(searchGroups.value);
        return allGroups.value;
    });
    function onSearchInput(value) {
        searchText.value = value;
        if (!value) searchGroups.value = null;
    }
    function onSearchClear() {
        onSearchInput('');
    }
    async function doSearch() {
        searchGroups.value = null;
        await nextTick();
        if (!isSearchResult.value) return;
        const { getGroups } = useEgsApi();
        searchGroups.value = await getGroups({
            type: isLive.value ? lobbyTypeLiveCasino : lobbyTypeSlots,
            groupName: searchText.value
        });
    }
    watch(searchText, useDebounce(doSearch, 500));
    return {
        searchText,
        isSearchInProgress,
        isNoSearchResults,
        list,
        allGroups,
        onSearchInput,
        onSearchClear
    };
}
