import { computed, onActivated, onMounted, ref, watch } from 'vue';
import { LobbyItemType } from '@leon-hub/api-sdk';
import { useWindowResize } from '@leon-hub/browser-composables';
import { LobbyGridLayoutType } from 'web/src/modules/lobby/enums';
import useLobbyGrid from 'web/src/modules/lobby/components/composables/useLobbyGrid';
var SlideGridType;
var SlideGridType1;
(SlideGridType1 = SlideGridType || (SlideGridType = {}))["COLUMN_SQUARE"] = "column-square", SlideGridType1["COLUMN_1"] = "column-1", SlideGridType1["COLUMN_2"] = "column-2", SlideGridType1["COLUMN_3"] = "column-3", SlideGridType1["COLUMN_4"] = "column-4", SlideGridType1["COLUMN_5"] = "column-5", SlideGridType1["COLUMN_6"] = "column-6", SlideGridType1["COLUMN_8"] = "column-8";
function getColumnCount(gridType) {
    switch(gridType){
        case "column-1":
            return 1;
        case "column-3":
            return 3;
        case "column-4":
            return 4;
        case "column-5":
            return 5;
        case "column-6":
            return 6;
        case "column-8":
            return 8;
        case "column-2":
        case "column-square":
        default:
            return 2;
    }
}
const swiperSlidesInitialLimit = process.env.VUE_APP_PRERENDER ? 10000 : 10;
const maxSlidesLimit = process.env.VUE_APP_PRERENDER ? 10000 : 1000;
export default function useLobbySwiper(props, emits) {
    const swiper = ref();
    const gridSize = ref(0);
    const slideGridType = ref("column-1");
    const swiperSlidesLimit = ref(swiperSlidesInitialLimit);
    function onVisibilityChanged(value) {
        if (value) swiperSlidesLimit.value = maxSlidesLimit;
    }
    const { gridType, getItemKey, getLobbyItemProps, emitClickLobbyItem } = useLobbyGrid(props, emits);
    watch(()=>props.rowsNumber, calculateGridSize);
    watch(()=>props.isSwiperBlock, calculateGridSize);
    watch(isSquare, calculateGridSize);
    const isOverflowHidden = computed(()=>![
            LobbyItemType.BANNERS,
            LobbyItemType.SPORTLINE_TOP_MATCHES
        ].includes(props.lobbyItemType));
    const swiperProps = computed(()=>({
            isAutoScroll: props.isSwiperAuto,
            isInfiniteLoop: props.isSwiperAuto,
            isBlock: props.isSwiperBlock,
            isScrollSnapEnabled: true,
            isOverflowVisible: !isOverflowHidden.value
        }));
    const swiperItems = computed(()=>{
        const result = [];
        let newArray = [];
        for (const item of props.items){
            newArray.push(item);
            if (newArray.length >= gridSize.value) {
                result.push(newArray);
                newArray = [];
            }
        }
        if (newArray.length > 0) result.push(newArray);
        return result.slice(0, swiperSlidesLimit.value);
    });
    function onResize(param) {
        let { deltaX } = param;
        if (0 !== deltaX) {
            const currentBlockSize = gridSize.value;
            const currentIndex = swiper.value?.getActiveSlideIndex() || 0;
            calculateGridSize();
            if (currentBlockSize !== gridSize.value) {
                const oldItems = currentBlockSize * (currentIndex + 1);
                const newIndex = Math.floor(oldItems / gridSize.value) - 1;
                if (newIndex !== currentIndex) swiper.value?.slideToSlide(newIndex);
            }
        }
    }
    function calculateGridSize() {
        calculateGridType();
        const columnCount = props.isSwiperBlock ? getColumnCount(slideGridType.value) : 1;
        gridSize.value = props.rowsNumber ? props.rowsNumber * columnCount : 0;
    }
    function calculateGridType() {
        if (!props.isSwiperBlock) {
            slideGridType.value = "column-1";
            return;
        }
        const width = window.innerWidth;
        if (isSquare()) {
            slideGridType.value = "column-square";
            return;
        }
        if (props.isModal) {
            slideGridType.value = "column-3";
            return;
        }
        if (width >= 1200) {
            slideGridType.value = "column-8";
            return;
        }
        "1";
        slideGridType.value = "column-6";
    }
    function isSquare() {
        return gridType.value === LobbyGridLayoutType.COLUMN_SQUARE_GAME_LARGE || gridType.value === LobbyGridLayoutType.COLUMN_SQUARE_GAME_SMALL || gridType.value === LobbyGridLayoutType.COLUMN_SQUARE_GAME_DEFAULT;
    }
    function emitSwiperScroll(event) {
        emits('swiper-scroll', event);
    }
    function resetScrollPosition() {
        swiper.value?.resetScrollPosition();
    }
    useWindowResize(onResize);
    onMounted(calculateGridSize);
    onActivated(calculateGridSize);
    return {
        swiper,
        gridType,
        slideGridType,
        swiperProps,
        isOverflowHidden,
        onVisibilityChanged,
        emitSwiperScroll,
        swiperItems,
        getItemKey,
        getLobbyItemProps,
        emitClickLobbyItem,
        gridSize,
        resetScrollPosition
    };
}
