import { toRef, computed } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useLiveStatus } from 'web/src/modules/sportline/composables/live-progress';
import { useSportlineEventCompetitors, useSportlineEventDateTime, useSportlineEventSport, useSportlineEventType } from 'web/src/modules/sportline/composables/sportline-event';
import { useSportlineEventMarketsPriority } from 'web/src/modules/sportline/composables/markets';
import { useLiveMatchProgress } from 'web/src/modules/sportline/composables/match-progress';
export default function useLobbySportTopMatchItem(props) {
    const router = useRouter();
    const settingsStore = useSportlineSettingsStore();
    const isTimeReversedIndicatorAvailable = toRef(settingsStore, 'isTimeReversedIndicatorAvailable');
    const isNoMarketsBlockedBtnEnabled = toRef(settingsStore, 'isNoMarketsBlockedBtnEnabled');
    const sportlineEvent = toRef(props, 'sportEventDetails');
    const sportlineEventLocation = computed(()=>{
        if (!sportlineEvent.value) return;
        return router.resolve({
            name: RouteName.SPORT_EVENT_DETAILS,
            params: {
                ...sportlineEvent.value.navigationParameters
            }
        });
    });
    const { matchProgress } = useLiveMatchProgress({
        sportEvent: sportlineEvent
    });
    const { date, time } = useSportlineEventDateTime({
        sportlineEvent,
        isFullDate: true
    });
    const { isLive: isLiveSportEvent } = useSportlineEventType({
        sportEvent: sportlineEvent
    });
    const { stage, totalScore, scoreDetailsLine } = useLiveStatus(matchProgress);
    const { primaryMarket } = useSportlineEventMarketsPriority({
        sportEvent: sportlineEvent
    });
    const { backgroundColor } = useSportlineEventSport({
        sportlineEvent
    });
    const { host: homeTeam, guest: awayTeam } = useSportlineEventCompetitors({
        sportlineEvent
    });
    function redirectToSportlineItem() {
        if (sportlineEventLocation.value) router.push(sportlineEventLocation.value);
    }
    return {
        matchProgress,
        scoreDetailsLine,
        isLiveSportEvent,
        isNoMarketsBlockedBtnEnabled,
        isTimeReversedIndicatorAvailable,
        totalScore,
        stage,
        date,
        time,
        primaryMarket,
        backgroundColor,
        homeTeam,
        awayTeam,
        sportlineEventLocation,
        redirectToSportlineItem
    };
}
