import { computed } from 'vue';
import { GameStartMethod } from 'web/src/modules/egs/submodules/navigation/enums';
import { getTestType } from './utils';
export default function useLobbyItem(props, emits) {
    const testType = computed(()=>getTestType(props));
    function onClickPlay() {
        emitClickLobbyItem(GameStartMethod.REAL);
    }
    function onClickDemo() {
        emitClickLobbyItem(GameStartMethod.DEMO);
    }
    function onClick() {
        emitClickLobbyItem();
    }
    function emitClickLobbyItem(selectedMethod) {
        emits('click-lobby-item', {
            item: props.item,
            selectedMethod
        });
    }
    return {
        testType,
        onClick,
        onClickPlay,
        onClickDemo
    };
}
