import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import RouteName, { CasinoRouteName } from '@leon-hub/routing-config-names';
import { TopWinnersTabType } from '@leon-hub/api-sdk';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useLobbyAllTopWinnersSection(props) {
    const router = useRouter();
    const { $translate } = useI18n();
    const allOptions = computed(()=>{
        const options = [];
        "1";
        options.push({
            id: TopWinnersTabType.SPORT,
            label: $translate('WEB2_ALL_WINNERS_TAB_SPORT').value
        });
        "1";
        options.push({
            id: TopWinnersTabType.EGS,
            label: $translate('WEB2_ALL_WINNERS_TAB_CASINO').value
        });
        return options;
    });
    const activeTab = ref(TopWinnersTabType.SPORT);
    watch(()=>props.tab, (newValue)=>{
        if (newValue) activeTab.value = newValue;
    }, {
        immediate: true
    });
    const isEgsTab = computed(()=>activeTab.value === TopWinnersTabType.EGS);
    function setActiveTab(tab) {
        activeTab.value = tab;
    }
    const switcherOptions = computed(()=>props.tab !== TopWinnersTabType.EGS ? allOptions.value : allOptions.value.reverse());
    const allWinnersLocation = computed(()=>{
        if (isEgsTab.value) return {
            name: CasinoRouteName.CASINO_LOBBY
        };
        "1";
        return {
            name: RouteName.SPORTLINE_TOP_EVENTS
        };
    });
    const allWinnersHref = computed(()=>router.resolve(allWinnersLocation.value).href);
    function onAllClick() {
        router.push(allWinnersLocation.value);
    }
    const items = computed(()=>isEgsTab.value ? props.topGames : props.topSports);
    return {
        switcherOptions,
        items,
        isEgsTab,
        allWinnersHref,
        setActiveTab,
        onAllClick
    };
}
