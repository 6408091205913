import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, withModifiers as _withModifiers, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { IconName, IconSize } from '@leon-hub/icons';
import { SportIcon } from '@components/sport-icon';
import { VIcon } from '@components/v-icon';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import PrimaryMarket from 'web/src/modules/sportline/views/primary-market/PrimaryMarket.vue';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import VSkeletonImage from 'web/src/components/Skeleton/VSkeletonImage/VSkeletonImage.vue';
import SportlineEventGameProgress from 'web/src/modules/sportline/components/game-progress/SportlineEventGameProgress.vue';
import { useTheme } from 'web/src/modules/theme/composables';
import { useLobbySportTopMatchItem } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbySportTopMatchItem',
    props: {
        sportEventDetails: {}
    },
    setup (__props) {
        const props = __props;
        const sportIconSize = IconSize.SIZE_56;
        const liveBadge = require('web/src/assets/images/live-event-badge.svg');
        const { matchProgress, isTimeReversedIndicatorAvailable, totalScore, stage, isLiveSportEvent, isNoMarketsBlockedBtnEnabled, homeTeam, awayTeam, backgroundColor, date, time, primaryMarket, scoreDetailsLine, sportlineEventLocation, redirectToSportlineItem } = useLobbySportTopMatchItem(props);
        const { isDark } = useTheme();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(sportlineEventLocation) ? 'a' : 'div'), {
                href: _unref(sportlineEventLocation)?.href,
                class: _normalizeClass(_ctx.$style['lobby-sport-top-match']),
                onClick: _withModifiers(_unref(redirectToSportlineItem), [
                    "prevent"
                ])
            }, {
                default: _withCtx(()=>[
                        _ctx.sportEventDetails ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['lobby-sport-top-match__bg'])
                            }, [
                                _unref(isDark) && _ctx.sportEventDetails.sport.representation.backgroundImage ? (_openBlock(), _createBlock(VImage, _mergeProps({
                                    key: 0
                                }, _ctx.sportEventDetails.sport.representation.backgroundImage, {
                                    "object-fit": _unref(ObjectFitOption).COVER,
                                    class: _ctx.$style['lobby-sport-top-match__bg-cover']
                                }), null, 16, [
                                    "object-fit",
                                    "class"
                                ])) : (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    class: _normalizeClass(_ctx.$style['lobby-sport-top-match__bg-image'])
                                }, null, 2))
                            ], 2),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['lobby-sport-top-match__informer'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['lobby-sport-top-match__top'])
                                }, [
                                    _createVNode(_unref(SportIcon), {
                                        "sport-name": _ctx.sportEventDetails.sport.representation.family,
                                        style: _normalizeStyle({
                                            fill: _unref(backgroundColor)
                                        }),
                                        size: _unref(IconSize).SIZE_14,
                                        class: _normalizeClass(_ctx.$style['lobby-sport-top-match__top-icon'])
                                    }, null, 8, [
                                        "sport-name",
                                        "style",
                                        "size",
                                        "class"
                                    ]),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['lobby-sport-top-match__top-region'])
                                    }, _toDisplayString(_ctx.sportEventDetails.region.name), 3),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['lobby-sport-top-match__top-league'])
                                    }, _toDisplayString(_ctx.sportEventDetails.league.name), 3),
                                    _unref(isLiveSportEvent) ? (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['lobby-sport-top-match__top-live-badge']),
                                        src: _unref(liveBadge),
                                        alt: "live"
                                    }, null, 10, _hoisted_1)) : _createCommentVNode("", true)
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['lobby-sport-top-match__info'])
                                }, [
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['lobby-sport-top-match__player'])
                                    }, [
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['lobby-sport-top-match__player-img-wrapper'])
                                        }, [
                                            _unref(homeTeam)?.logo ? (_openBlock(), _createBlock(VImage, {
                                                key: 0,
                                                src: _unref(homeTeam).logo,
                                                "object-fit": _unref(ObjectFitOption).COVER,
                                                "is-lazy": ""
                                            }, null, 8, [
                                                "src",
                                                "object-fit"
                                            ])) : (_openBlock(), _createBlock(_unref(SportIcon), {
                                                key: 1,
                                                "sport-name": _ctx.sportEventDetails.sport.representation.family,
                                                style: _normalizeStyle({
                                                    fill: _unref(backgroundColor)
                                                }),
                                                size: _unref(sportIconSize)
                                            }, null, 8, [
                                                "sport-name",
                                                "style",
                                                "size"
                                            ]))
                                        ], 2),
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['lobby-sport-top-match__player-name'])
                                        }, _toDisplayString(_unref(homeTeam)?.name), 3)
                                    ], 2),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['lobby-sport-top-match__timeline'])
                                    }, [
                                        _ctx.sportEventDetails.hasZeroMargin ? (_openBlock(), _createElementBlock("div", {
                                            key: 0,
                                            class: _normalizeClass(_ctx.$style['lobby-sport-top-match__zero-margin'])
                                        }, [
                                            _createVNode(VBadge, {
                                                kind: _unref(BadgeKind).SQUARE_INVERT,
                                                label: _ctx.$t('WEB2_SPORT_EVENTS_IMPROVED_ODDS')
                                            }, null, 8, [
                                                "kind",
                                                "label"
                                            ])
                                        ], 2)) : _createCommentVNode("", true),
                                        _unref(isLiveSportEvent) ? (_openBlock(), _createElementBlock(_Fragment, {
                                            key: 1
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['lobby-sport-top-match__score'])
                                            }, [
                                                _createElementVNode("span", null, _toDisplayString(_unref(totalScore).host), 1),
                                                _cache[1] || (_cache[1] = _createElementVNode("span", null, ":", -1)),
                                                _createElementVNode("span", null, _toDisplayString(_unref(totalScore).guest), 1)
                                            ], 2),
                                            _unref(scoreDetailsLine) ? (_openBlock(), _createElementBlock("div", {
                                                key: 0,
                                                class: _normalizeClass(_ctx.$style['lobby-sport-top-match__live-details'])
                                            }, " (" + _toDisplayString(_unref(scoreDetailsLine)) + ") ", 3)) : _createCommentVNode("", true),
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['lobby-sport-top-match__stage'])
                                            }, [
                                                _createElementVNode("span", {
                                                    class: _normalizeClass(_ctx.$style['lobby-sport-top-match__stage-text'])
                                                }, _toDisplayString(_unref(stage)), 3),
                                                _createVNode(SportlineEventGameProgress, {
                                                    "match-progress": _unref(matchProgress),
                                                    "font-size": 12,
                                                    "is-time-reversed-indicator-available": _unref(isTimeReversedIndicatorAvailable)
                                                }, null, 8, [
                                                    "match-progress",
                                                    "font-size",
                                                    "is-time-reversed-indicator-available"
                                                ])
                                            ], 2),
                                            _createCommentVNode("", true)
                                        ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                                            key: 2
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['lobby-sport-top-match__date'])
                                            }, _toDisplayString(_unref(date)), 3),
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['lobby-sport-top-match__time'])
                                            }, _toDisplayString(_unref(time)), 3)
                                        ], 64))
                                    ], 2),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['lobby-sport-top-match__player'])
                                    }, [
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['lobby-sport-top-match__player-img-wrapper'])
                                        }, [
                                            _unref(awayTeam)?.logo ? (_openBlock(), _createBlock(VImage, {
                                                key: 0,
                                                src: _unref(awayTeam).logo,
                                                "object-fit": _unref(ObjectFitOption).COVER,
                                                "is-lazy": ""
                                            }, null, 8, [
                                                "src",
                                                "object-fit"
                                            ])) : (_openBlock(), _createBlock(_unref(SportIcon), {
                                                key: 1,
                                                "sport-name": _ctx.sportEventDetails.sport.representation.family,
                                                style: _normalizeStyle({
                                                    fill: _unref(backgroundColor)
                                                }),
                                                size: _unref(sportIconSize)
                                            }, null, 8, [
                                                "sport-name",
                                                "style",
                                                "size"
                                            ]))
                                        ], 2),
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['lobby-sport-top-match__player-name'])
                                        }, _toDisplayString(_unref(awayTeam)?.name), 3)
                                    ], 2)
                                ], 2),
                                _unref(primaryMarket) ? (_openBlock(), _createBlock(PrimaryMarket, {
                                    key: 0,
                                    market: _unref(primaryMarket),
                                    "sport-event-details": _ctx.sportEventDetails,
                                    onClickRunner: _unref(redirectToSportlineItem)
                                }, null, 8, [
                                    "market",
                                    "sport-event-details",
                                    "onClickRunner"
                                ])) : _unref(isNoMarketsBlockedBtnEnabled) ? (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    class: _normalizeClass(_ctx.$style['lobby-sport-top-match__blocked-btn']),
                                    onClick: _cache[0] || (_cache[0] = _withModifiers(()=>{}, [
                                        "stop",
                                        "prevent"
                                    ]))
                                }, [
                                    _createVNode(_unref(VIcon), {
                                        name: _unref(IconName).LOCK,
                                        size: _unref(IconSize).SIZE_24
                                    }, null, 8, [
                                        "name",
                                        "size"
                                    ])
                                ], 2)) : _createCommentVNode("", true)
                            ], 2)
                        ], 64)) : (_openBlock(), _createBlock(VSkeletonImage, {
                            key: 0
                        }))
                    ]),
                _: 1
            }, 8, [
                "href",
                "class",
                "onClick"
            ])), [
                [
                    _directive_auto_id,
                    'LobbySportTopMatchItem'
                ]
            ]);
        };
    }
});
