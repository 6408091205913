import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { LobbyItemType, LobbyItemWidget } from '@leon-hub/api-sdk';
import { useLobbyItem } from 'web/src/modules/lobby/components/LobbyItem/composables';
import LobbyEgsGamesItem from 'web/src/modules/lobby/components/LobbyEgsGamesItem/LobbyEgsGamesItem.vue';
import { LobbyItemLayoutType } from 'web/src/modules/lobby/enums';
import LobbyEgsGroupItem from 'web/src/modules/lobby/components/LobbyEgsGroupItem/LobbyEgsGroupItem.vue';
import LobbyEgsTopWinnerItem from 'web/src/modules/lobby/components/LobbyEgsTopWinnerItem/LobbyEgsTopWinnerItem.vue';
import { isSwiperWidget } from 'web/src/modules/lobby/utils';
import LobbyPromotionItem from 'web/src/modules/lobby/components/LobbyPromotionItem/LobbyPromotionItem.vue';
import LobbyEgsTopWinnerSimpleItem from 'web/src/modules/lobby/components/LobbyEgsTopWinnerSimpleItem/LobbyEgsTopWinnerSimpleItem.vue';
import LobbyPromotionItemV2 from 'web/src/modules/lobby/components/LobbyPromotionItemV2/LobbyPromotionItemV2.vue';
import LobbySportTopMatchItem from 'web/src/modules/lobby/components/LobbySportTopMatchItem/LobbySportTopMatchItem.vue';
import LobbyBannerItem from 'web/src/modules/lobby/components/LobbyBannerItem/LobbyBannerItem.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbyItem',
    props: {
        item: {},
        lobbyItemType: {},
        widgetId: {},
        layoutType: {},
        isNameVisible: {
            type: Boolean
        },
        isEditMode: {
            type: Boolean
        },
        isRemoved: {
            type: Boolean
        },
        hasImageForceLoad: {
            type: Boolean
        },
        jackpotAnimationDuration: {}
    },
    emits: [
        "click-lobby-item"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { testType, onClick, onClickPlay, onClickDemo } = useLobbyItem(props, emits);
        function isEgsGame(item, typeId) {
            return typeId === LobbyItemType.GAMES_CATEGORY;
        }
        function isEgsGroups(item, typeId) {
            return typeId === LobbyItemType.GROUPS;
        }
        function isEgsTopWinners(item, typeId) {
            return typeId === LobbyItemType.TOP_WINNERS;
        }
        function isLobbyPromotion(item, typeId) {
            return typeId === LobbyItemType.PROMOTIONS;
        }
        function isLobbySportItem(item, typeId) {
            return typeId === LobbyItemType.SPORTLINE_TOP_MATCHES;
        }
        function isLobbyBannerItem(item, typeId) {
            return typeId === LobbyItemType.BANNERS;
        }
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            return isEgsGame(_ctx.item, _ctx.lobbyItemType) ? _withDirectives((_openBlock(), _createBlock(LobbyEgsGamesItem, {
                key: 0,
                item: _ctx.item,
                "is-description-hidden": false,
                "is-panel-visible": _ctx.widgetId === _unref(LobbyItemWidget).BIG_SMALL_ROWS_LIST || _ctx.widgetId === _unref(LobbyItemWidget).SQUARE_ROWS_LIST,
                "is-square-image": _ctx.layoutType === _unref(LobbyItemLayoutType).GAME_SQUARE,
                "is-jackpot": _ctx.widgetId === _unref(LobbyItemWidget).JACKPOT_LIST || _ctx.widgetId === _unref(LobbyItemWidget).JACKPOT_SWIPE_LIST,
                "jackpot-animation-duration": _ctx.jackpotAnimationDuration,
                "is-edit-mode": _ctx.isEditMode,
                "is-removed": _ctx.isRemoved,
                "has-image-force-load": _ctx.hasImageForceLoad,
                "is-name-visible": _ctx.isNameVisible,
                onClick: _unref(onClick),
                onClickDemo: _unref(onClickDemo),
                onClickPlay: _unref(onClickPlay)
            }, null, 8, [
                "item",
                "is-description-hidden",
                "is-panel-visible",
                "is-square-image",
                "is-jackpot",
                "jackpot-animation-duration",
                "is-edit-mode",
                "is-removed",
                "has-image-force-load",
                "is-name-visible",
                "onClick",
                "onClickDemo",
                "onClickPlay"
            ])), [
                [
                    _directive_data_test,
                    {
                        el: 'egs-lobby-item',
                        type: _unref(testType)
                    }
                ]
            ]) : isEgsGroups(_ctx.item, _ctx.lobbyItemType) ? _withDirectives((_openBlock(), _createBlock(LobbyEgsGroupItem, {
                key: 1,
                item: _ctx.item,
                "is-big": "",
                onClick: _unref(onClick)
            }, null, 8, [
                "item",
                "onClick"
            ])), [
                [
                    _directive_data_test,
                    {
                        el: 'egs-lobby-item',
                        type: _unref(testType)
                    }
                ]
            ]) : isEgsTopWinners(_ctx.item, _ctx.lobbyItemType) ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 2
            }, [
                _withDirectives((_openBlock(), _createBlock(LobbyEgsTopWinnerItem, {
                    key: 1,
                    item: _ctx.item,
                    "is-small": _unref(isSwiperWidget)(_ctx.widgetId),
                    onClick: _unref(onClick)
                }, null, 8, [
                    "item",
                    "is-small",
                    "onClick"
                ])), [
                    [
                        _directive_data_test,
                        {
                            el: 'egs-lobby-item',
                            type: _unref(testType)
                        }
                    ]
                ])
            ], 64)) : isLobbyPromotion(_ctx.item, _ctx.lobbyItemType) ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 3
            }, [
                _withDirectives((_openBlock(), _createBlock(LobbyPromotionItem, {
                    key: 1,
                    item: _ctx.item,
                    onClick: _unref(onClick)
                }, null, 8, [
                    "item",
                    "onClick"
                ])), [
                    [
                        _directive_data_test,
                        {
                            el: 'egs-lobby-item',
                            type: _unref(testType)
                        }
                    ]
                ])
            ], 64)) : isLobbySportItem(_ctx.item, _ctx.lobbyItemType) ? (_openBlock(), _createBlock(LobbySportTopMatchItem, {
                key: 4,
                "sport-event-details": _ctx.item
            }, null, 8, [
                "sport-event-details"
            ])) : isLobbyBannerItem(_ctx.item, _ctx.lobbyItemType) ? (_openBlock(), _createBlock(LobbyBannerItem, {
                key: 5,
                item: _ctx.item
            }, null, 8, [
                "item"
            ])) : _createCommentVNode("", true);
        };
    }
});
