import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ButtonHeight, ButtonKind } from '@leon-hub/module-buttons';
import { IconName } from '@leon-hub/icons';
import LobbyEgsNoGames from 'web/src/modules/lobby/components/LobbyEgsNoGames/LobbyEgsNoGames.vue';
import LobbyGrid from 'web/src/modules/lobby/components/LobbyGrid/LobbyGrid.vue';
import LobbySectionHeader from 'web/src/modules/lobby/components/LobbySectionHeader/LobbySectionHeader.vue';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import { useLobbyGridSection } from 'web/src/modules/lobby/components/LobbyGridSection/composables';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';
import { ProgressHeight } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import LobbyFilters from 'web/src/modules/lobby/components/LobbyFilters/LobbyFilters.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbyGridSection',
    props: {
        widgetId: {},
        gridType: {},
        lobbyItemType: {},
        lobbyItems: {},
        actionLabel: {},
        actionHref: {},
        headerName: {},
        isModal: {
            type: Boolean
        },
        hasNext: {
            type: Boolean
        },
        total: {},
        isFirstPage: {
            type: Boolean
        },
        jackpotAnimationDuration: {},
        showSkeleton: {
            type: Boolean
        },
        topWinnersFilter: {},
        isItemNameVisible: {
            type: Boolean
        },
        isEditable: {
            type: Boolean
        },
        headerIcon: {},
        titleType: {},
        isHeaderWithHorizontalMargin: {
            type: Boolean
        },
        isMoreLoading: {
            type: Boolean
        },
        isLoadMoreButtonVisible: {
            type: Boolean
        },
        isFiltersAllowed: {
            type: Boolean
        },
        noBackground: {
            type: Boolean
        },
        isCustomHomepage: {
            type: Boolean
        }
    },
    emits: [
        "click-lobby-item",
        "delete-items",
        "action-click",
        "top-winners-filter-click",
        "section-scroll",
        "swiper-scroll"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { isEdit, wrapper, lobbyGridBaseProps, headerProps, isSmallHeader, isEditBtnEnabled, isHeaderVisible, tabsProps, tabs, noGamesDesc, noGamesTitle, totalCounter, totalText, currentCounter, emitActionClick, onEditClick, emitTopWinnersFilterClick, onClickLobbyItem, emitScrollTop } = useLobbyGridSection(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "wrapper",
                ref: wrapper,
                class: _normalizeClass([
                    [
                        _ctx.$style['lobby-grid-section']
                    ],
                    [
                        _ctx.$style[`lobby-grid-section--widget-${_ctx.widgetId}`]
                    ],
                    [
                        _ctx.$style[`lobby-grid-section--item-type-${_ctx.lobbyItemType}`]
                    ]
                ])
            }, [
                _unref(isHeaderVisible) ? (_openBlock(), _createBlock(LobbySectionHeader, _mergeProps({
                    key: 0
                }, _unref(headerProps), {
                    "is-grid-layout": "",
                    class: {
                        [_ctx.$style['lobby-grid-section__header']]: true,
                        [_ctx.$style['lobby-grid-section__header--small']]: _unref(isSmallHeader)
                    },
                    onActionClick: _unref(emitActionClick)
                }), _createSlots({
                    _: 2
                }, [
                    void 0,
                    _unref(isEditBtnEnabled) ? {
                        name: "action",
                        fn: _withCtx(()=>[
                                (_openBlock(), _createBlock(LButton, {
                                    key: 1,
                                    label: _unref(isEdit) ? _ctx.$t('WEB2_DONE') : _ctx.$t('WEB2_CHANGE'),
                                    kind: _unref(isEdit) ? _unref(ButtonKind).PRIMARY : _unref(ButtonKind).BASE,
                                    height: _unref(ButtonHeight).MEDIUM,
                                    "is-uppercase": false,
                                    onClick: _unref(onEditClick)
                                }, null, 8, [
                                    "label",
                                    "kind",
                                    "height",
                                    "onClick"
                                ]))
                            ]),
                        key: "1"
                    } : void 0
                ]), 1040, [
                    "class",
                    "onActionClick"
                ])) : _createCommentVNode("", true),
                _ctx.topWinnersFilter ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _ctx.showSkeleton ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass({
                            [_ctx.$style['lobby-grid-section__tabs']]: true,
                            [_ctx.$style['lobby-grid-section__tabs--skeleton']]: true
                        })
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tabs).length, (item)=>(_openBlock(), _createElementBlock("div", {
                                key: item,
                                class: _normalizeClass(_ctx.$style['lobby-grid-section__skeleton-tab'])
                            }, null, 2))), 128))
                    ], 2)) : (_openBlock(), _createBlock(VTabs, _mergeProps({
                        key: 0
                    }, _unref(tabsProps), {
                        class: _ctx.$style['lobby-grid-section__tabs'],
                        "is-bordered": "",
                        onTabClick: _unref(emitTopWinnersFilterClick)
                    }), null, 16, [
                        "class",
                        "onTabClick"
                    ]))
                ], 64)) : _createCommentVNode("", true),
                _ctx.lobbyItems.length || _ctx.showSkeleton ? _createCommentVNode("", true) : (_openBlock(), _createBlock(LobbyEgsNoGames, {
                    key: 2,
                    text: _unref(noGamesDesc),
                    heading: _unref(noGamesTitle),
                    "is-button-hidden": _ctx.isEditable,
                    class: _normalizeClass({
                        [_ctx.$style['lobby-grid-section__no-games']]: true,
                        [_ctx.$style['lobby-grid-section__no-games--filter']]: _ctx.topWinnersFilter
                    })
                }, null, 8, [
                    "text",
                    "heading",
                    "is-button-hidden",
                    "class"
                ])),
                _createVNode(LobbyGrid, _mergeProps(_unref(lobbyGridBaseProps), {
                    class: _ctx.$style['lobby-grid-section__grid'],
                    onClickLobbyItem: _unref(onClickLobbyItem)
                }), null, 16, [
                    "class",
                    "onClickLobbyItem"
                ]),
                _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LobbyGridSection'
                ]
            ]);
        };
    }
});
