import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createVNode as _createVNode, unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import VHeadlineBanner from 'web/src/components/HeadlineBanner/VHeadlineBanner/VHeadlineBanner.vue';
import { useLobbyPromotionItem } from 'web/src/modules/lobby/components/LobbyPromotionItem/composables';
import LobbyPromotionTitlePanel from 'web/src/modules/lobby/components/LobbyPromotionTitlePanel/LobbyPromotionTitlePanel.vue';
import VSkeletonImage from 'web/src/components/Skeleton/VSkeletonImage/VSkeletonImage.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbyPromotionItem',
    props: {
        item: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { backgroundString } = useLobbyPromotionItem(props);
        function onClick(event) {
            if (props.item) emit('click', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.item?.url ? 'a' : 'div'), {
                href: _ctx.item?.url,
                class: _normalizeClass(_ctx.$style['lobby-promotion-item']),
                onClick: _withModifiers(onClick, [
                    "stop",
                    "prevent"
                ])
            }, {
                default: _withCtx(()=>[
                        _ctx.item ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _createVNode(VHeadlineBanner, {
                                class: _normalizeClass(_ctx.$style['lobby-promotion-item__image-container']),
                                "main-image": _ctx.item.image,
                                "background-image": _ctx.item.bgImage || void 0,
                                "background-color": _ctx.item.bgColor || void 0,
                                "is-internal-link": "",
                                url: _ctx.item.url,
                                "is-div-container": "",
                                "aspect-ratio": 1.49,
                                "fix-height-img": "",
                                "no-min-height": ""
                            }, null, 8, [
                                "class",
                                "main-image",
                                "background-image",
                                "background-color",
                                "url"
                            ]),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['lobby-promotion-item__title-block'])
                            }, [
                                _createVNode(LobbyPromotionTitlePanel, {
                                    title: _ctx.item.headline || void 0,
                                    description: _ctx.item.secondaryText || void 0,
                                    style: _normalizeStyle({
                                        background: _unref(backgroundString)
                                    })
                                }, null, 8, [
                                    "title",
                                    "description",
                                    "style"
                                ])
                            ], 2)
                        ], 64)) : (_openBlock(), _createBlock(VSkeletonImage, {
                            key: 1
                        }))
                    ]),
                _: 1
            }, 8, [
                "href",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'LobbyPromotionItem'
                ]
            ]);
        };
    }
});
